import React from 'react';
import { Link } from "react-router-dom";

import InfoPage from './InfoPage';

import '../../css/table.css';


const RecordTable = (props) => {
    const { table, folder_id, hasToken, onStartProcessRecord } = props;

    if (!table) {
        return (
            <InfoPage message="Record list has no table" kind="danger" />
        )
    }

    const record_rows = table.map((r, i) =>  {
        // console.log(r);
        const rec_id = r.id;
        const view_ref = hasToken ? `/folder/${folder_id}/record/${rec_id}` : `/record/${rec_id}`;
        const report_ref = hasToken ? `/#/folder/${folder_id}/report/${rec_id}` : `/#/report/${rec_id}`;
        const statusStr = r.rec_proc_ok ? (<span className="badge badge-success" title={r.proc_ver}>processed</span>) : 
                r.rec_proc ? (<span className="badge badge-secondary" title={r.proc_ver}>processed</span>) : 
                r.processing === "starting" ? (<span className="badge badge-warning">starting...</span>) : 
               (r.processing === "started") || (r.processing === "in-progress") ? (<span className="badge badge-warning">in progress...</span>) : 
                r.processing === "error" ? (<span className="badge badge-danger" title={r.error}>failed</span>) : 
                r.processing === undefined ? "-" : r.processing;
        const ecgLengthStr = r.ok_secs_str ? r.ok_secs_str : "-";
        const qiStr = r.qi_avg ? r.qi_avg : "-";
        const procTitleStr = !r.rec_proc ? "Process" : "Re-process";
        const procBtnStr = (((!r.processing) || (r.processing === 'error')) && onStartProcessRecord) ? (
            <button className="btn btn-outline-secondary btn-sm r-sm" onClick={(e) => { onStartProcessRecord(rec_id, folder_id) }}>{procTitleStr}</button>
            ) : null;
        return (
          <tr key={i} >
            <td className="text-center" > {i + 1} </td>
            <td className="text-left aa"> <Link className="btn text-left w-100 f-sm2" to={view_ref}>{rec_id}</Link> </td>
            <td className="text-left f-sm2"> {r.rec_date} </td>
            <td className="text-center f-sm"> {r.rec_length_str} </td>
            <td className="text-center f-sm"> {statusStr} </td>
            <td className="text-center f-sm"> {ecgLengthStr} </td>
            <td className="text-center f-sm"> {qiStr} </td>
            <td className="text-right">
                  {procBtnStr}
                  <a className="btn btn-outline-secondary btn-sm r-sm ml-2" href={report_ref} 
                        target="_blank" rel="noopener noreferrer">Report</a>
            </td>
          </tr>
        )
      }
    )

    return (
        <div className='r-table'>
            <table className='table table-hover'>
                <thead className="thead-dark">
                    <tr>
                        <th className="text-center" width="10%"> NN </th>
                        <th className="text-left"> Record ID </th>
                        <th className="text-left"> Date </th>
                        <th className="text-center"> Length </th>
                        <th className="text-center"> Processed </th>
                        <th className="text-center"> ECG </th>
                        <th className="text-center"> QI </th>
                        <th className="text-center"> Operation </th>
                    </tr>
                </thead>
                <tbody>
                    {record_rows}
                </tbody>
                <tfoot className="thead-dark">
                    <tr>
                        <th className="text-center" > NN </th>
                        <th className="text-left"> Total: {record_rows.length} </th>
                        <th className="text-left"> Date </th>
                        <th className="text-center"> Length </th>
                        <th className="text-center"> Processed </th>
                        <th className="text-center"> ECG </th>
                        <th className="text-center"> QI </th>
                        <th className="text-center"> Operation </th>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}


export default RecordTable;