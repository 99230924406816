import React from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

import Header from './static_pages/header';
import RecordList from './items/RecordList';
import Footer from './static_pages/footer';

import { getRecordListRequest, runProcessRecordRequest } from '../redux/requests';


class FolderPage extends React.Component {

  constructor(props) {
    super(props);
    // this.folder_id = props.match.params.id;
    this.folder_id = props.folder_id;
    this.onStartProcessRecord = this.onStartProcessRecord.bind(this);
    this.onUpdateRecordList = this.onUpdateRecordList.bind(this);
    this.timer = null;
  }

  componentDidMount() {
    if (this.folder_id) {
      this.props.getRecordList(this.folder_id);
      console.log("==set loading folder record list: " + this.folder_id);
    }
  }

  onStartProcessRecord(recId, folder_id) {
    console.log('onStartProcessRecord', recId, folder_id);
    this.props.runProcessRecordRequest(recId, folder_id);
  }

  onUpdateRecordList() {
    console.log("==start updating record list", this.folder_id);
    this.props.getRecordList(this.folder_id, true);
  }

  hasRecordInProgress() {
    if (!this.props.recordList) return false;

    const rr = this.props.recordList.find(r => r.processing ? true : false);
    console.log('hasRecordInProgress', rr);
    return rr ? true : false;

  }


  render() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    if (this.hasRecordInProgress()) {
      console.log('setTimeout');
      this.timer = setTimeout(this.onUpdateRecordList, 10000);
    }

    return (
      <div id="mm-0" className="mm-page mm-slideout">
        <div id="page">
          <Header />
          <RecordList recordList={this.props.recordList} 
            folder={this.props.folderName} folder_id={this.folder_id} 
            hasToken={this.folder_id && this.folder_id.length > 30} hasStat={this.props.folderHasStat}
            recordListLoading={this.props.recordListLoading} 
            loadError={this.props.loadError} 
            onStartProcessRecord={this.onStartProcessRecord}/>
          <Footer />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.auth.userName && state.auth.userToken,
    folderName: state.record.folderName,
    folderHasStat: state.record.folderHasStat,
    recordList: state.record.recordList,
    recordListLoading: state.record.recordListLoading,
    loadError: state.record.loadError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRecordList: (token) => dispatch(getRecordListRequest(token)),
    runProcessRecordRequest: (recId, token) => dispatch(runProcessRecordRequest(recId, false, token)),

  };
}



const FolderPageContainer = connect(mapStateToProps, mapDispatchToProps)(FolderPage);


const FolderPageMatch = (props) => {
  let {id} = useParams()
  return (<FolderPageContainer folder_id={id} />);
}

export default FolderPageMatch;
