import React from 'react';
import { useState } from 'react';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import SplitButton from 'react-bootstrap/SplitButton';

import { getRhythmCode, toMSstr } from '../tools/calc-funcs';

const css = `
.ecg-range-container {
    background-color: #E0E0F0;
    height: 38px;
}

.ecg-range-panel {
    margin: auto;
}

.ecg-range-button-panel {
    padding-top: 4px;
    padding-left: 20px;
}

.w-btn-range {
   min-width: 26pt;
}
`

const rhythmCodes = [
    { code: 0,  value: "Undefined (Udef)" },
    { code: -1, value: "-" },
    { code: 16, value: "Normal sinus (NSR)" },
    { code: 17, value: "Sinus tachycardia (TA)" },
    { code: 18, value: "Sinus bradycardia (BRA)" },
    { code: 20, value: "Sinus arrythmia (SA)" },
    { code: 80, value: "Extrasystole (E)" },
    { code: 145,  value: "Noisy or no signal (NoS)" },
    { code: 146,  value: "Induced periodic signal (IPS)" },
    { code: -1, value: "-" },
    { code: 65, value: "Atrial Fibrillation (AFib)" },
    { code: 67, value: "Atrial Flutter (AFl)" },
];

const dropList = () => {
    return rhythmCodes.map((item, index) => {
        return item.code < 0 ? (<Dropdown.Divider key={index}/>) : (<Dropdown.Item key={index} eventKey={item.code}>{item.value}</Dropdown.Item>);
    });
};

const titleByCode = (code) => {
    const rItem = rhythmCodes.find(item => {return item.code === code} );
    return rItem ? rItem.value : code;
}

const RangePanel = (props) => {
    const [toolMode, setToolMode] = useState(0);
    const [rhytmCode, setRhythmCode] = useState(-1);

    const { w, pos, selRange, rhythm, frequency, beatMode, onChangeBeatMode, hasRangeIntersection, showAnnotation, onToggleShowAnnotation, updatingStatus, updateError, 
        storeRhythmRangeEvent, clearRhythmRangeEvent, clearErrorEvent } = props;

    const onChangeTypeEvent = (e) => {
        setRhythmCode(parseInt(e));
    }

    const onChangeMode = (mode) => {
        setToolMode(mode);
        if (mode === 1) {
            if (!showAnnotation) {
                onToggleShowAnnotation();
            }
        }
        onChangeBeatMode(mode === 2);
    }

    const onStoreRange = () => {
        if (storeRhythmRangeEvent) storeRhythmRangeEvent(rhytmCode);
        setRhythmCode(-1);
    }

    const onClearRange = () => {
        if (clearRhythmRangeEvent) clearRhythmRangeEvent();
        setRhythmCode(-1);
    }

    const onClearError = () => {
        if (clearErrorEvent) clearErrorEvent();
    }

    const hasRange = selRange && selRange.start > 0;

    const rCode = hasRange ? getRhythmCode(selRange.start*frequency, rhythm) : 0;
    const dropListItems = dropList();
    // console.log(rCode);

    if (!hasRange && rhytmCode >= 0) {
        setRhythmCode(-1);
    }

    if (!showAnnotation && toolMode === 1) {
        setToolMode(0);
    }

    if (beatMode && toolMode !== 2) {
        setToolMode(2);
    }

    const rhytmTitle = rhytmCode < 0 ? titleByCode(rCode) : titleByCode(rhytmCode);

    const saveBtn = (rhytmCode >= 0)? (
        <ButtonGroup aria-label="tool-save" size="sm" className="ml-2">
            <Button variant="outline-dark" title="Save selected range" onClick={onStoreRange}><i className="fa fa-cloud-upload" aria-hidden="true"></i></Button>
        </ButtonGroup>) 
        : null;

    const clearBtn = hasRangeIntersection ? (
        <ButtonGroup aria-label="tool-clear" size="sm" className="ml-2">
            <Button variant="outline-danger" title="Clear selected range" onClick={onClearRange}><i className="fa fa-eraser" aria-hidden="true"></i></Button>
        </ButtonGroup>) 
        : null;

    const posItem = (toolMode === 0 && pos) ?
        (<Button className="mr-2" variant="outline-dark">position: {toMSstr(pos)} ms</Button>)
        : null;

    const distanceItem = (toolMode === 0 && hasRange) ? 
            (<Button  variant="outline-dark">distance: {toMSstr(selRange.finish - selRange.start)} ms</Button>) 
            : null;

    const timeItems = (toolMode === 0) ? (
        <ButtonGroup aria-label="ruler-data" size="sm" className="ml-2">
            {posItem}
            {distanceItem}        
        </ButtonGroup>                        
    ) : null;

    const rangeItems = (toolMode === 1 && hasRange) ? (
        <>
            <ButtonGroup aria-label="range-data" size="sm" className="ml-2">
                <Button variant="outline-dark">{toMSstr(selRange.start)} - {toMSstr(selRange.finish)} ms</Button>
            </ButtonGroup>                        
            <ButtonGroup aria-label="range-code" size="sm" className="ml-2">
                <SplitButton
                                id="range-type"
                                size="sm"
                                variant="outline-dark"
                                toggleLabel=""
                                title={rhytmTitle}
                                align={{ lg: 'start' }}
                                onSelect={onChangeTypeEvent}
                                
                >
                {dropListItems}
                </SplitButton>                            
            </ButtonGroup> 
            {saveBtn}                       
            {clearBtn}
        </>) : null;

    const updateComp = updatingStatus ? (
        <ButtonGroup aria-label="range-data" size="sm" className="ml-2">
            <Button variant="outline-dark"> Updating ... </Button>
        </ButtonGroup>                        
    ) : null; 

    const updateErrorComp = updateError ? (
        <ButtonGroup aria-label="range-data" size="sm" className="ml-2">
            <Button variant="danger" title={updateError} onClick={onClearError}> update failed </Button>
        </ButtonGroup>
    ) : null; 

    return (
        <>
            <div className='ecg-range-container'>
                <div className='ecg-range-panel' style={{'width': w}}>
                    <div className='ecg-range-button-panel'>
                        <ButtonGroup aria-label="btn-mode" size="sm">
                            <Button title="time ruler mode" className="w-btn-range" active={toolMode === 0 ? 1 : 0} variant="outline-dark" onClick={() => onChangeMode(0) }><i className="fa fa-i-cursor" aria-hidden="true"></i></Button>
                            <Button title="annotation edit mode" className="w-btn-range" active={toolMode === 1 ? 1 : 0} variant="outline-dark" onClick={() => onChangeMode(1) }><i className="fa fa-edit" aria-hidden="true"></i></Button>
                            <Button title="beat info" className="w-btn-range" active={toolMode === 2 ? 1 : 0} variant="outline-dark" onClick={() => onChangeMode(2) }><i className="fa fa-plus-square-o" aria-hidden="true"></i></Button>
                        </ButtonGroup>                        
                        {timeItems}{rangeItems}
                        {updateComp}
                        {updateErrorComp}
                    </div>
                </div>
            </div>
            <style>{css}</style>
        </>
    )
}


export default RangePanel;
