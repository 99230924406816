import React from 'react';
import {connect} from 'react-redux';
import { useParams } from "react-router-dom";

// import Header from './static_pages/header';
import ReportView from './items/ReportView';
import FooterShort from './static_pages/footer-short';

import { clearRevPolarityAction, clearRecordErrorAction } from '../redux/actions';
import { getRecordRequest, runProcessRecordRequest, annotationUpdateRequest } from '../redux/requests';

import '../css/main.css';


class ReportPage extends React.Component {
  constructor(props) {
    super(props);
    this.onRecalcRecord = this.onRecalcRecord.bind(this);
    this.onReloadRecord = this.onReloadRecord.bind(this);
    this.onClearRevPolarity = this.onClearRevPolarity.bind(this);
    this.onCheckRecordProcessing = this.onCheckRecordProcessing.bind(this);
    this.onAnnotationUpdate = this.onAnnotationUpdate.bind(this);
    this.onClearError = this.onClearError.bind(this);
    this.token = null;
    this.timer = null;

    this.folder_id = props.folder_id;
    this.record_id = props.record_id;

  }

  componentDidMount() {
    if (this.record_id) {
      this.token = this.folder_id;
      this.props.getRecord(this.record_id, this.token, false, true); // no quiet, calc 
      console.log("==set loading record (+calc): ", this.record_id);
    } else {
      console.log("error: no record id assigned");
    }
  }

  onRecalcRecord(recordId, revPolarity) {
    if (recordId && this.props.recordId === recordId) {
      this.props.runProcessRecord(recordId, revPolarity, this.token);
    }
  }

  onReloadRecord(recordId) {
    if (recordId && this.props.recordId === recordId) {
      this.props.getRecord(recordId, this.token, false, false); // no quiet, no calc 
    }
  }

  onAnnotationUpdate(recordId, code, start, finish, isClear) {
    this.props.annotationUpdate(recordId, this.token, code, start, finish, isClear);
  }

  onClearError(recordId)  {
    this.props.clearRecordError(recordId);
  }

  onClearRevPolarity() {
    this.props.clearRevPolarity();
  }

  onCheckRecordProcessing() {
    this.timer = null;
    if (this.props.recordId) {
      this.props.getRecord(this.props.recordId, this.token, true, false); // quiet, no calc 
    } 
  }

  render() {

    if (this.props.recordProcessing && 
       (this.props.processState === 'started' || this.props.processState === 'processing')) {
      console.log("check record processing");
      if (!this.timer)  {
        this.timer = setTimeout(() => {
          this.onCheckRecordProcessing();
        }, 3000);
      }
    }

    console.log("draw record: ", this.props.recordId);

    return(
      <div id="mm-0" className="mm-page mm-slideout">
        <div id="page">
          <main>
            <div className='hero_home'>
              <ReportView recordData={this.props.recordData} recordId={this.props.recordId}
                recordLoading={this.props.recordLoading} 
                recordError={this.props.processState ? this.props.processError : this.props.loadError}
                recordProcessing={this.props.recordProcessing} 
                processState={this.props.processState}
                processCount={this.props.processCount}
                processRevPolarity={this.props.processRevPolarity}
                recordProgress={this.props.recordProgress}
                updatingStatus={this.props.updatingStatus}
                updateError={this.props.updateError}
                recalcRecordHandler={this.onRecalcRecord}
                reloadRecordHandler={this.onReloadRecord}
                annotationUpdateHandler={this.onAnnotationUpdate}
                clearErrorHandler={this.onClearError}
                clearRevPolarityHandler={this.onClearRevPolarity}/>
            </div>
          </main>

          <FooterShort />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
    return {
      loggedIn: state.auth.userName && state.auth.userToken,
      token: state.record.userToken,
      recordId: state.record.recordId,
      recordData: state.record.recordData,
      recordLoading: state.record.recordLoading,
      recordProcessing: state.record.recordProcessing,
      recordProgress: state.record.recordProgress,
      loadError: state.record.loadError,
      processState: state.record.processState,
      processCount: state.record.processCount,
      processRevPolarity: state.record.processRevPolarity,
      processError: state.record.processError,
      updatingStatus: state.record.updatingStatus,
      updateError: state.record.updateError,
    };
}

function mapDispatchToProps(dispatch) {
    return {
      getRecord: (recordId, token, quiet, calc) => dispatch(getRecordRequest(recordId, token, quiet, calc)),
      annotationUpdate: (recordId, token, code, start, finish, isClear) => 
                         dispatch(annotationUpdateRequest(recordId, token, code, start, finish, isClear)),
      clearRecordError: (recordId) => dispatch(clearRecordErrorAction(recordId)),
      runProcessRecord: (recordId, revPolarity, token) => dispatch(runProcessRecordRequest(recordId, revPolarity, token)),
      clearRevPolarity: () => dispatch(clearRevPolarityAction())
    };
}


const ReportPageContainer = connect(mapStateToProps, mapDispatchToProps)(ReportPage);

const ReportPageMatch = (props) => {
  let {id, folder_id} = useParams()
  return (<ReportPageContainer record_id={id} folder_id={folder_id} />);
}

export default ReportPageMatch;