import React, {useRef} from 'react';
import { connect } from 'react-redux';

import { setRecordOffset, setSignalWindowWidth, setInfoHR_RR } from '../../redux/actions';

import "../../css/ecg-signal.css";

import { draw1mv, drawSignalAsPoly } from "../tools/signal-funcs";
import { useContainerDimensions } from "../tools/dimensions";
import { getTickByX, getXByTick } from "../tools/calc-funcs";
import { PAPER_CELL_WIDTH, PAPER_CELL_HEIGHT, MV1_COLOR, 
    PAPER_SPEED_LIST, ECG_AMP_LIST, localeStr } from "../../const/signal_consts";
import ButtonsControl from "../tools/btns-control";




const isBeatInTime = (b, offset, w) => {
    return ((b.r >= offset) && (b.r <= offset+w));
}

const getBeatMark = (b, i, offset, paperSpeed, frequency, mm_x) => {
    // const bb = BeatMark("green");
    const x = getXByTick(b.r, offset, paperSpeed, frequency, mm_x);
    return (
        <div key={i} className="pos-abs x-centered ecg-marker-color" style={{left: x, top: 0}}>
            <i className="fa fa-caret-down"></i>
        </div>
        )
}

const getBeatHRmark = (b, i, offset, paperSpeed, frequency, mm_x) => {
    if (b.rr < 10) {
        return null;
    }
    const x = getXByTick(b.r - b.rr / 2, offset, paperSpeed, frequency, mm_x);
    const hr = Math.round(60 * frequency / b.rr);
    return (
        <label key={i} className="pos-abs x-centered ecg-hr-mark" style={{ left: x }}>
            {hr}
        </label>

    );
}

const getBeatRRmark = (b, i, offset, paperSpeed, frequency, mm_x) => {
    if (b.rr < 10) {
        return null;
    }
    const x = getXByTick(b.r - b.rr / 2, offset, paperSpeed, frequency, mm_x);
    const rr = Math.round(b.rr * 1000 / frequency);
    return (
        <label key={i} className="pos-abs x-centered ecg-rr-mark" style={{ left: x }}>
            {rr}
        </label>

    );
}


const makeTimeTicks = (width, offset, paperSpeed, frequency, mm_x, recordStartDateTime) => {
    let timeTicks = [];
    let ta = frequency * Math.trunc(offset / frequency);
    if (ta <= offset) ta += frequency;
    let tsec = Math.trunc(ta / frequency);
    while (true) {
        const x = getXByTick(ta, offset, paperSpeed, frequency, mm_x);
        if (x > width-10) break;

        let tt = new Date(recordStartDateTime.valueOf());
        tt.setSeconds(tt.getSeconds() + tsec);
        const tStr = tt.toLocaleTimeString(localeStr).slice(0, -2);
        const tick = (
           <label key={ta} className="pos-abs x-centered text-center" style={{ left: x, color: MV1_COLOR }}>
                <i>|</i><br />{tStr}
            </label>
           );
        timeTicks.push(tick);
        ta += frequency;
        tsec++;
    }
    return (<>{timeTicks}</>);
}

const getAverageBeatHR_RR = (beats, frequency) => {
    let nBeats = 0;
    let sumRR = 0;
    beats.slice(1, beats.length).forEach(b=>{
        const rr = Number.isInteger(b.rr) ? b.rr : parseInt(b.rr);
        if (rr > 0) {
            nBeats++; sumRR += rr;
        }
    });
    console.log(beats, sumRR, nBeats);

    return sumRR === 0 ? [-1, -1] : [Math.round(60 * frequency * nBeats / sumRR), Math.round(sumRR * 1000 / nBeats / frequency)];
}

const makeBeatMarks = (beats, width, offset, paperSpeed, frequency, mm_x, infoParamMode, beatMarkerVisible) => {
    if (beats === undefined) {
        return [[], [], -1];
    }

    const w = getTickByX(width, paperSpeed, frequency, mm_x);
    // console.log(w, width);
    const vBeats = beats.filter(b => isBeatInTime(b, offset, w));

    const beatMarks = beatMarkerVisible ? vBeats.map((b, i) => getBeatMark(b, i, offset, paperSpeed, frequency, mm_x)) : null;
    const parMarks = infoParamMode === 0 ? 
        vBeats.slice(1, vBeats.length).map((b, i) => getBeatHRmark(b, i, offset, paperSpeed, frequency, mm_x)) :
        vBeats.slice(1, vBeats.length).map((b, i) => getBeatRRmark(b, i, offset, paperSpeed, frequency, mm_x));
    const [avgHR, avgRR] = getAverageBeatHR_RR(vBeats, frequency);

    return [beatMarks, parMarks, avgHR, avgRR];
}

const getSignalControlLeftPos = (w) => {
    const n = Math.max(0, Math.floor(w / 2 / PAPER_CELL_WIDTH) - 2);  
    return PAPER_CELL_WIDTH*n;
}

const SignalControlItem = (props) => {
    const compRef = useRef();
    const { width, height } = useContainerDimensions(compRef);
    if (width < 50) {
        return (
        <div ref={compRef} className='ecg-signal-panel'>
        </div>); 
    }

    const { recordData, recordStartDateTime, recordLength, offset, setRecordOffset, 
        signalWindowWidth, setSignalWindowWidth, infoParamMode, infoHR, setInfoHR_RR, 
        paperSpeed, ecgAmp, timeTickVisible, beatMarkerVisible } = props;
    const { proc_signal, org_signal, beats, frequency } = recordData;  

    if (signalWindowWidth !== width) {
        setSignalWindowWidth(width);
    }

    const signal = !proc_signal ? org_signal : proc_signal; 
    const selected = [0, -1];

    const mm_x = PAPER_CELL_WIDTH / 10;
    const [beatMarks, parMarks, avgHR, avgRR] = makeBeatMarks(beats, width, offset, paperSpeed, frequency, mm_x, infoParamMode, beatMarkerVisible); 
    const timeTicks = timeTickVisible ? makeTimeTicks(width, offset, paperSpeed, frequency, mm_x, recordStartDateTime) : null;
    const svg1mv = timeTickVisible ? draw1mv(ecgAmp) : null;
    const tPage = Math.round(width * 10 / (paperSpeed *PAPER_CELL_WIDTH));
    const svgSignal = drawSignalAsPoly(signal, offset, selected, width, Math.min(height, 8 * PAPER_CELL_HEIGHT), frequency, paperSpeed, ecgAmp)

    const controlLeft = getSignalControlLeftPos(signalWindowWidth);

    const onRecordStart = () => {
        if (offset > 0) {
            setRecordOffset(0);
        }
    }

    const onRecordPageBack = () => {
        if (offset > 0) {
            setRecordOffset(Math.max(0, offset - tPage*frequency));
        }
    }

    const onRecordStepBack = () => {
        if (offset > 0) {
            setRecordOffset(Math.max(0, offset - frequency));
        }
    }

    const onRecordStepFwd = () => {
        if (offset + tPage * frequency < recordLength) {
            setRecordOffset(offset + frequency);
        }
    }

    const onRecordPageFwd = () => {
        if (offset + tPage * frequency < recordLength - frequency) {
            const lastSec = Math.floor(recordLength / frequency);
            setRecordOffset(Math.min(offset + tPage * frequency, (lastSec - tPage + 1)*frequency));
        }
    }

    const onRecordFinish = () => {
        const lastSec = Math.floor(recordLength / frequency);
        if (offset < + (lastSec - tPage) * frequency) {
            setRecordOffset((lastSec - tPage + 1) * frequency);
        }
    }

    if (avgHR !== infoHR) {
        setInfoHR_RR(avgHR, avgRR);
    }

    return (
        <div ref={compRef} className='ecg-signal-panel'>
            <div className='ecg-mv'>
                {svg1mv}
            </div>
            <div className='ecg-signal'>
                {svgSignal}               
            </div>
            <div className='ecg-ticks'>
                {timeTicks}
            </div>
            <div className='ecg-beats'>
                {beatMarks}
                {parMarks}
            </div>
            <div className='ecg-control'>
                <ButtonsControl left={controlLeft}
                    onStart={onRecordStart} 
                    onPageBack={onRecordPageBack} 
                    onStepBack={onRecordStepBack} 
                    onStepFwd={onRecordStepFwd} 
                    onPageFwd={onRecordPageFwd} 
                    onFinish={onRecordFinish}
                  />
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        recordData: state.record.recordData,
        recordStartDateTime: state.record.recordStartDateTime,
        recordLength: state.record.recordLength,
        offset: state.record.recordOffset,
        signalWindowWidth: state.record.recordSignalWindowWidth,
        infoHR: state.record.recordInfoHR,
        infoParamMode: state.record.recordInfoParamMode,  
        paperSpeed: PAPER_SPEED_LIST[state.record.recordPaperSpeedIndex],
        ecgAmp: ECG_AMP_LIST[state.record.recordEcgAmpIndex],
        timeTickVisible: state.record.recordTimeTickVisible,
        beatMarkerVisible: state.record.recordBeatMarkerVisible    
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setRecordOffset: (offset) => dispatch(setRecordOffset(offset)),
        setInfoHR_RR: (hr, rr) => dispatch(setInfoHR_RR(hr, rr)),
        setSignalWindowWidth: (value) => dispatch(setSignalWindowWidth(value)),
    };
}


const SignalControlContainer = connect(mapStateToProps, mapDispatchToProps)(SignalControlItem);
export default SignalControlContainer;
