import React, { useRef, useState } from 'react';  // , { useState}
// import { Collapse } from 'react-collapse';

import { useContainerDimensions } from "../tools/dimensions"; 

import InfoPage from './InfoPage';
import ProgressPage from './ProgressPage';
import SignalRowItem, { getRowWidth } from './SignalRowItem';
import ToolPanel from './ToolPanel';
import RangePanel from './RangePanel';

import { cutBeats, beatAlign } from "../tools/beat-funcs";
import { detectWindowLengthInSec } from '../tools/signal-funcs';
import { checkUserRange, checkRangeAndRhythmIntersection } from '../tools/calc-funcs';
import {
  ECG_AMP_LIST, C_DEFAULT_SIGNAL_GAIN_INDEX, 
  PAPER_SPEED_LIST, C_DEFAULT_PAPED_SPEED_INDEX, 
} from '../../const/signal_consts';


const CHECKED = 'checked';

function getLoadInfoMessage(loading, error, recordId=null) {
  if (loading) {
    return `Record "${recordId}" loading in progress ...`;
  }
  if (error) {
    return `Record "${recordId}" load error: ${error}`;
  }
  return `Record "${recordId}" load error: Undefined`;
}

function getProgressInfoMessage(processState, error, recordId = null) {
  if (processState==='starting') {
    return `Record "${recordId}" processing starting ...`;
  }

  if (processState === 'started') {
    return `Record "${recordId}" processing started ...`;
  }

  if (processState === 'processing') {
    return `Record "${recordId}" processing in progress ...`;
  }

  if (error) {
    return `Record "${recordId}" processing error: ${error}`;
  }
  return `Record "${recordId}" processing error: Undefined`;
}

function getInfoKind(info) {
  if (info) {
    return "info";
  }
  return "danger";
}


// function collapseSymbol(isOpen) {
//   return isOpen? "-" : "+";
// }

const css = `

.body {
  overflow: hidden;
}

.content-record {
}

.ecg-control-container {
  background-color: #fafafb;
  position: fixed; 
  top: 0px;
  height: 200px;
  width: 100%;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;!important /* Standard syntax */
}

.ecg-rows-container {
  margin-top: 200px;
  overflow: auto;
}

.ecg-signal-row {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.cl-red {
  color: red;
}

.bullet-icon  {
  position: relative;
  top: -2px;
}

`

const ReportView = (props) => {
  const compRef = useRef();
  const { width } = useContainerDimensions(compRef);

  const [signalGainIndex, setSignalGainIndex] = useState(C_DEFAULT_SIGNAL_GAIN_INDEX);
  const [paperSpeedIndex, setPaperSpeedIndex] = useState(C_DEFAULT_PAPED_SPEED_INDEX);
  const [rr_hr_mode, setRR_HR_Mode] = useState(false);
  const [beatMode, setBeatMode] = useState(true);
  const [beatInfoPage, setBeatInfoPage] = useState(0);
  const [selectedBeat, setSelectedBeat] = useState(2);
  const [noCutSignal, setNoCutSignal] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(null); 
  const [selectedRange, setSelectedRange] = useState({start: -1, finish: -1}); 
  const [reportWindowWidth, setReportWindowWidth] = useState(-1);
  const [showRawData, setShowRawData] = useState("");
  const [showSmoothedData, setShowSmoothedData] = useState(CHECKED);
  const [showRevPolarity, setShowRevPolarity] = useState("");
  const [showRRs, setShowRRs] = useState(CHECKED);
  const [showBeatMarkers, setShowBeatMarkers] = useState(CHECKED);
  const [showRhythm, setShowRhythm] = useState(CHECKED);
  const [showAnnotation, setShowAnnotation] = useState(CHECKED);

  const onToggleShowRawData = () => {
    setShowRawData(showRawData === CHECKED ? "" : CHECKED);
  }

  const onToggleNoCutSignal = () => {
    setNoCutSignal(!noCutSignal);
  }

  const onChangeBeatMode = (mode) => {
    if (beatMode !== mode) {
      setBeatMode(mode);
    }
  }

  const onChangeSelectedBeat = (beatNo) => {
    if (selectedBeat !== beatNo) {
      setSelectedBeat(beatNo);
    }
  }

  const onToggleRR_HR_Mode = () => {
    setRR_HR_Mode(! rr_hr_mode);
  }

  const onToggleBeatInfoPage = () => {
    setBeatInfoPage((beatInfoPage + 1) % 2);
  }

  const onToggleShowSmoothedData = () => {
    setShowSmoothedData(showSmoothedData === CHECKED ? "" : CHECKED);
  }

  const onToggleShowRevPolarity = () => {
    setShowRevPolarity(showRevPolarity === CHECKED ? "" : CHECKED);
  }

  const onToggleShowRRs = () => {
    setShowRRs(showRRs === CHECKED ? "" : CHECKED);
  }

  const onToggleShowBeatMarkers = () => {
    setShowBeatMarkers(showBeatMarkers === CHECKED ? "" : CHECKED);
  }

  const onToggleShowRhythm = () => {
    setShowRhythm(showRhythm === CHECKED ? "" : CHECKED);
  }

  const onToggleShowAnnotation = () => {
    setShowAnnotation(showAnnotation === CHECKED ? "" : CHECKED);
  }

  if (width < 350) {
    return (
        <div ref={compRef} className='content-record'>
        </div>
      );
  }

  if (reportWindowWidth !== width) {
    setReportWindowWidth(width);
  }

  const onStoreRhythmRange = (rhythmCode) => {
    const start = beatAlign(Math.round(selectedRange.start * frequency), beats);
    const finish = beatAlign(Math.round(selectedRange.finish * frequency), beats);
    // console.log("on store range: ", rhythmCode, start, finish);
    if (annotationUpdateHandler) {
      annotationUpdateHandler(recordId, rhythmCode, start, finish, false);
      setSelectedRange(-1, -1);
    }
  }

  const onClearRhythmRange = () => {
    const start = Math.round(selectedRange.start * frequency);
    const finish = Math.round(selectedRange.finish * frequency);
    console.log("on clear range: ", start, finish); 
    if (annotationUpdateHandler) {
      annotationUpdateHandler(recordId, 0, start, finish, true);
      setSelectedRange(-1, -1);
    }
  }

  const onClearError = () => {
     clearErrorHandler(recordId);
  }
  
  const onRecalcRecord = () => {
    if (recalcRecordHandler) {
      recalcRecordHandler(recordId, showRevPolarity);
    }  
  }

  const onReload = () => {
    if (reloadRecordHandler) {
      reloadRecordHandler(recordId);
    }  
  }

  const onPositionUpdate = (pos) => {
    setMarkerPosition(pos);
  }

  const onRangeUpdate = (start, finish) => {
    setSelectedRange({start: start, finish: finish});
  }

  const signalGain = ECG_AMP_LIST[signalGainIndex];
  const paperSpeed = PAPER_SPEED_LIST[paperSpeedIndex];

  const { recordId, recordData, recordError, recordLoading, recordProcessing,
    processState, processRevPolarity, recordProgress, updatingStatus, updateError,
    recalcRecordHandler, reloadRecordHandler, clearRevPolarityHandler, annotationUpdateHandler, clearErrorHandler } = props;

  if (!recordData) {
    if (processState) {
      // console.log(processState, recordProcessing);
      return (
        <div className='content'>
          <ProgressPage message={getProgressInfoMessage(processState, recordError, recordId)} 
            kind={getInfoKind(recordProcessing)} progress={recordProgress}
            cancelHandler={onReload} recalcHandler={onRecalcRecord}/>
        </div>
      );

    } 

    return (
      <div className='content'>
        <InfoPage message={getLoadInfoMessage(recordLoading, recordError, recordId)} kind={getInfoKind(recordLoading)}/>
      </div>
    );
  }

  if (processRevPolarity && showRevPolarity) {
    clearRevPolarityHandler();
    setShowRevPolarity("");
  }

  const { frequency, org_signal, proc_signal, rec_date, proc_ver, proc_date, beats, user_beats, rhythm, mixed_rhythm, pw } = recordData;  
  // console.log(mixed_rhythm);

  if (!proc_signal) {
      return (
          <div className='content'>
            <InfoPage message="record has no field: proc_signal" kind="danger" />
          </div>
      )
    }

  if (!frequency) {
      return (
          <div className='content'>
            <InfoPage message="record has no field: frequency" kind="danger" />
          </div>
      )
  }

  const nSec = Math.ceil(proc_signal.length / frequency);
  const timeUnit = 5 * 25 / paperSpeed;
  const nUnit = Math.max(1, Math.min(3, Math.floor(detectWindowLengthInSec(reportWindowWidth, paperSpeed) / timeUnit)));
  const wcSec = nUnit * timeUnit;
  const sRest = nSec % wcSec;
  const nRows = noCutSignal ? Math.ceil(nSec / wcSec) : sRest <= (2 * wcSec / 5) ? Math.floor(nSec / wcSec) : Math.ceil(nSec / wcSec);
  const sliceLength = wcSec * frequency;
  const totalLength = Math.min(proc_signal.length, nRows * sliceLength);
  const hasAnnotation = checkUserRange(mixed_rhythm); 
  const hasRangeIntersection = checkRangeAndRhythmIntersection(selectedRange, mixed_rhythm, frequency); 

  const rowItems = [];
  for (let i = 0; i < nRows; i++)  {
    const offset = i * sliceLength;
    const beatsSlice = beats !== undefined ? cutBeats(beats, offset, sliceLength + frequency) : undefined; 
    const userBeatsSlice = user_beats !== undefined ? cutBeats(user_beats, offset, sliceLength + frequency) : undefined; 
    rowItems.push((<SignalRowItem key={i} offset={i * wcSec} length={wcSec} 
      frequency={frequency} gain={signalGain} paperSpeed={paperSpeed} showRevPolarity={showRevPolarity}
      signal={showSmoothedData === CHECKED ? proc_signal.slice(offset, offset + sliceLength) : null}
      signal2={showRawData === CHECKED ? org_signal? org_signal.slice(offset, offset + sliceLength) : null : null}
      beats={beatsSlice} userBeats={userBeatsSlice}
      rhythm={showAnnotation? mixed_rhythm : rhythm}
      showRRs={showRRs === CHECKED} showBeatMarkers={showBeatMarkers === CHECKED} 
      showRhythm={showRhythm === CHECKED}
      beatMode={beatMode} selectedBeat={selectedBeat}
      rr_hr_mode={rr_hr_mode}
      selectedRange={selectedRange}
      PositionUpdateHandler={onPositionUpdate}
      RangeUpdateHandler={onRangeUpdate}
      BeatChangeHandler={onChangeSelectedBeat} />))
  }
  const rowWidth = getRowWidth(wcSec, paperSpeed);

  const toolControls = {
    signalGainIndex: signalGainIndex, setSignalGainIndex: setSignalGainIndex,
    paperSpeedIndex: paperSpeedIndex, setPaperSpeedIndex: setPaperSpeedIndex,
    beatMode: beatMode, selectedBeat: selectedBeat, 
    beatInfoPage: beatInfoPage, onToggleBeatInfoPage: onToggleBeatInfoPage,
    rr_hr_mode: rr_hr_mode, onToggleRR_HR_Mode: onToggleRR_HR_Mode,
    noCutSignal: noCutSignal, onToggleNoCutSignal: onToggleNoCutSignal,
    showRawData: showRawData, onToggleShowRawData: onToggleShowRawData,
    showSmoothedData: showSmoothedData, onToggleShowSmoothedData: onToggleShowSmoothedData,
    showRevPolarity: showRevPolarity, onToggleShowRevPolarity: onToggleShowRevPolarity,
    showRRs: showRRs, onToggleShowRRs: onToggleShowRRs,
    showBeatMarkers: showBeatMarkers, onToggleShowBeatMarkers: onToggleShowBeatMarkers,
    showRhythm: showRhythm, onToggleShowRhythm: onToggleShowRhythm,
    showAnnotation: showAnnotation, onToggleShowAnnotation: onToggleShowAnnotation, 
    hasAnnotation: hasAnnotation,
    onRecalcRecord: onRecalcRecord
  };

  return(
    <div ref={compRef} className='content-record'>
      <div className='ecg-control-container'>
        <ToolPanel w={rowWidth} nUnit={nUnit} totalLength={totalLength} proc_ver={proc_ver} proc_date={proc_date}
              rec_date={rec_date} frequency={frequency} beats={beats} pw={pw} rhythm={showAnnotation ? mixed_rhythm : rhythm} 
              controls={toolControls}/>
        <RangePanel w={rowWidth} pos={markerPosition} selRange={selectedRange} frequency={frequency} rhythm={mixed_rhythm} 
              hasRangeIntersection={hasRangeIntersection} 
              beatMode={beatMode} onChangeBeatMode={onChangeBeatMode}
              showAnnotation={showAnnotation} onToggleShowAnnotation={onToggleShowAnnotation}
              updatingStatus={updatingStatus} updateError={updateError}
              storeRhythmRangeEvent={onStoreRhythmRange}
              clearRhythmRangeEvent={onClearRhythmRange}
              clearErrorEvent={onClearError}/>
      </div>
      <div className='ecg-rows-container'>
        {rowItems}
      </div>
      <style>{css}</style>
    </div>
    )
}

export default ReportView;
