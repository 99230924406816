import React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import './Header.css';


class Header extends React.Component {

  render() {

    return(
      <header id="navtoolbar" className="header_sticky sticky p-0">
      <nav className="navbar navbar-light">
      <NavLink exact to="/" className="navbar-brand" title="ECG Demo">
        <img src="/img/logo_2x.png" data-retina="true" alt="" width="250" height="50" className="img-fluid"/>
      </NavLink>


  <ul className="nav justify-content-end">
  <div style={{display: "flex", marginRight: "40px"}}>
    <li className="nav-item">
      <NavLink exact to="/" className="nav-link" title="Home"><img src="/img/home-30.png" alt="home"/></NavLink>
    </li>
  </div>

  <div style={{display: "flex", marginRight: "40px"}}>
    <li className="nav-item" >
    </li>
  </div>
    <div style={{display: "flex", border: "1px solid #ccc"}}>
            <li className="nav-item"><NavLink exact to="/register" className="nav-link" title="registration"><img src="/img/add-user-30.png" alt="registration" /></NavLink></li>
            <li className="nav-item"><NavLink exact to="/login" className="nav-link" title="login"><img src="/img/login-30.png" alt="login" /></NavLink></li>
    </div>

  </ul>

</nav>
    	</header>
    )
  }
}

function mapStateToProps(state) {
    return {
      userName: state.auth.userName,
      loggedIn: state.auth.userName && state.auth.userToken,
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}


const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header);
export default HeaderContainer;
