import React from 'react';
import { Link } from "react-router-dom";

import InfoPage from './InfoPage';

import '../../css/table.css';


function getRowColorClass(r) {
    if (r.marked === 1 && r.found === 1) {
        return "bc_ok";
    }
    if (r.marked === 0 && r.found === 0) {
        return "bc_ok";
    }

    if (r.marked === 0 && r.found === 1) {
        return "bc_al";
    }

    return "bc_err";

}


const FolderStatTable = (props) => {
    const { table, total, folder_id } = props;

    if (!table) {
        return (
            <InfoPage message="Record stat has no table" kind="danger" />
        )
    }

    const record_rows = table.map((r, i) =>  {
        // console.log(r);

        const rec_id = r.name;
        const view_ref = folder_id ? `/folder/${folder_id}/record/${rec_id}` : `/record/${rec_id}`;
        const report_ref = folder_id ? `/#/folder/${folder_id}/report/${rec_id}` : `/#/report/${rec_id}`;

        return (
          <tr key={i} className={getRowColorClass(r)}>
            <td className="text-center" > {i + 1} </td>
            <td className="text-left aa"> <Link className="btn text-left w-100" to={view_ref}>{rec_id}</Link> </td>
            <td className="text-center f-sm"> {r.marked} </td>
            <td className="text-center f-sm"> {r.found} </td>
            <td className="text-center f-sm"> {r.saw} </td>
            <td className="text-center f-sm"> {r.noise} </td>
            <td className="text-center f-sm"> {r.total_secs} </td>
            <td className="text-center f-sm"> {r.ecg_secs} </td>
            <td className="text-center f-sm"> {r.qi_avg} </td>
            <td className="text-center f-sm"> {r.beats} </td>
            <td className="text-center f-sm cg"> {r.tp} </td>
            <td className="text-center f-sm cg"> {r.tn}</td>
            <td className="text-center f-sm cy"> {r.fp} </td>
            <td className="text-center f-sm cr"> {r.fn}</td>
            <td className="text-right">
                    <a className="btn btn-outline-secondary btn-sm r-sm ml-2" href={report_ref}
                        target="_blank" rel="noopener noreferrer">Report</a>
            </td>
          </tr>
        )
      }
    )

    return (
        <div className='r-table'>
            <table className='table table-hover'>
                <thead className="thead-dark">
                    <tr>
                        <th className="text-center" width="10%"> NN </th>
                        <th className="text-left"> Record ID </th>
                        <th className="text-center"> AF marked</th>
                        <th className="text-center"> AF found</th>
                        <th className="text-center"> Saw</th>
                        <th className="text-center"> Noise</th>
                        <th className="text-center"> Length (secs)</th>
                        <th className="text-center"> ECG (secs)</th>
                        <th className="text-center"> QI</th>
                        <th className="text-center"> Beats </th>
                        <th className="text-center"> True-pos </th>
                        <th className="text-center"> True-neg </th>
                        <th className="text-center"> False-pos </th>
                        <th className="text-center"> False-neg </th>
                        <th className="text-center"> Ops </th>
                    </tr>
                </thead>
                <tbody>
                    {record_rows}
                </tbody>
                <tfoot className="thead-dark">
                    <tr>
                        <th className="text-center" > NN </th>
                        <th className="text-left"> Total: {record_rows.length} </th>
                        <th className="text-center" title="Total AF marked"> {total.s_marked} </th>
                        <th className="text-center" title="Total AF found"> {total.s_found} </th>
                        <th className="text-center" title="Total Saw"> {total.s_saw} </th>
                        <th className="text-center" title="Total Noise"> {total.s_noise} </th>
                        <th className="text-center" title="Average length (secs)"> {total.a_total_secs} </th>
                        <th className="text-center" title="Average ECG length (secs)"> {total.a_ecg_secs} </th>
                        <th className="text-center" title="Average QI"> {total.a_qi} </th>
                        <th className="text-center" title="Total beats"> {total.s_beats} </th>
                        <th className="text-center cg" title="Total true-positive"> {total.s_tp} </th>
                        <th className="text-center cg" title="Total true-negative"> {total.s_tn} </th>
                        <th className="text-center cy" title="Total false-positive"> {total.s_fp} </th>
                        <th className="text-center cr" title="Total false-negative"> {total.s_fn} </th>
                        <th className="text-center"> Ops </th>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}


export default FolderStatTable;