import React from 'react';
import { Link } from "react-router-dom";

import InfoPage from './InfoPage';
import RecordTable from './RecordTable';

function getInfoKind(loading) {
  if (loading) {
    return "info";
  }
  return "danger";
}

function getInfoMessage(loading, error) {
  if (loading) {
    return `Record list loading in progress ...`;
  }
  if (error) {
    return `Record list load error: ${error}`;
  }
  return `Record list load error: Unknown`;
}

const getFolderHeader = (folder_id, folder, hasStat) => {
  const statCell = hasStat ? (<div>
    <Link className="btn btn-outline-secondary" to={`/folder/${folder_id}/stat`}>Recognition statictics</Link>
  </div>) : null;
  return (
    <div className="folder-name row">
      <div className="mt-1">
        <label>Folder</label>
      </div>
      <div className="mt-1">
        <label><b>{folder}</b></label>
      </div>
      {statCell}
    </div>
  );

}


const RecordList = (props) => {

  const { recordList, folder_id, folder, hasToken, hasStat, folderList, recordListLoading, loadError, onStartProcessRecord } = props;
  console.log(props);

  if (!recordList && !folderList) {
    return (
      <main>
        <a href="#top" name="top" >&nbsp;</a>
        <div className='hero_home'>
          <div className='content'>
            <InfoPage message={getInfoMessage(recordListLoading, loadError)} kind={getInfoKind(recordListLoading)} />
          </div>
        </div>
      </main>

    );
  }

  const recordTable = recordList ? (
        <RecordTable table={recordList} folder_id={folder_id} hasToken={hasToken} onStartProcessRecord={onStartProcessRecord} />
      ) : null;
      
  const folderHeader = folder ? getFolderHeader(folder_id, folder, hasStat) : getFolderHeader(null, "Unknown", null);

  return (
    <main>
      <div className='hero_home'>
        <div className='content-record'>
          {folderHeader}
          {recordTable}
        </div>
      </div>
    </main>
  )

}


export default RecordList;