import { ACTIONS, C_PAGE_REVIEW } from './actions';
import { updateRecordData } from './state-tools';
import { defaultPaperSpeedIndex, defaultEcgAmpIndex } from '../const/signal_consts';

const getRecordStartDateTime = (recordData) => {
    const startDateTime = recordData.rec_date;
    return startDateTime === undefined ? new Date('12/12/2022 10:00:00') : new Date(startDateTime);
}

const getRecordLength = (recordData) => {
    let signal = recordData.proc_signal;
    if (!signal) signal = recordData.org_signal;
    if (!signal) signal = recordData.signal;

    return !signal ? 0 : signal.length;
}

export const initialStateRecord = {
    userToken: null,
    recordId: null,
    defaultPage: C_PAGE_REVIEW, // C_PAGE_RHYTHM, 

    folderList: null,
    folderName: null,
    folderHasStat: null,
    recordList: null,
    recordListLoading: false,
    recordListUpdateCount: 0,

    folderStatTable: null,
    folderStatTotal: null,
    folderStatLoading: false,

    recordData: null,
    recordLoading: false,
    loadError: null,

    recordProcessing: null,
    recordProgress: null,

    processState: null,
    processCount: null,
    processRevPolarity: null,
    processError: null,

    updatingStatus: false,
    updateError: null,

    recordStartDateTime: null,
    recordLength: null,
    recordOffset: 0,
    recordSignalWindowWidth: -1,
    recordInfoHR: null,
    recordInfoRR: null,
    recordPaperSpeedIndex: defaultPaperSpeedIndex,
    recordEcgAmpIndex: defaultEcgAmpIndex,
    recordTimeTickVisible: true,
    recordBeatMarkerVisible: true,
    recordInfoParamMode: 0
}

export function record(state = initialStateRecord, action) {
    switch (action.type) {

        case ACTIONS.RECORD.START_LOADING_FOLDER_LIST:
            return {
                ...state,
                userToken: action.payload,
                folderList: null,
                recordList: null,
                folderName: null,
                folderHasStat: null,
                loadError: null,
                recordListLoading: true
            }

        case ACTIONS.RECORD.START_LOADING_RECORD_LIST:
            return {
                ...state,
                userToken: action.payload,
                recordList: null,
                folderName: null,
                folderHasStat: null,
                loadError: null,
                recordListLoading: true
            }

        case ACTIONS.RECORD.SET_LOAD_FOLDER_LIST_DATA:
            return {
                ...state,
                folderList: action.payload,
                loadError: null,
                recordListLoading: false
            }

        case ACTIONS.RECORD.SET_LOAD_RECORD_LIST_DATA:
            return {
                ...state,
                recordList: action.payload.records,
                folderName: action.payload.folder,
                folderHasStat: action.payload.hasStat,
                recordListUpdateCount: 0,
                loadError: null,
                recordListLoading: false
            }

        case ACTIONS.RECORD.SET_LOAD_RECORD_LIST_ERROR:
            return {
                ...state,
                folderList: null,
                recordList: null,
                folderName: null,
                folderHasStat: null,
                loadError: action.payload,
                recordListLoading: false
            }

        case ACTIONS.RECORD.START_LOADING_STAT_TABLE:
            return {
                ...state,
                userToken: action.payload,
                folderStatTable: null,
                folderStatTotal: null, 
                folderName: null,
                loadError: null,
                folderStatLoading: true
            }

        case ACTIONS.RECORD.SET_LOAD_STAT_TABLE_DATA:
            return {
                ...state,
                folderStatTable: action.payload.records,
                folderStatTotal: action.payload.total,
                folderName: action.payload.folder,
                loadError: null,
                folderStatLoading: false
            }

        case ACTIONS.RECORD.SET_LOAD_STAT_TABLE_ERROR:
            return {
                ...state,
                folderStatTable: null,
                folderStatTotal: null, 
                folderName: null,
                loadError: action.payload,
                folderStatLoading: false
            }

        case ACTIONS.RECORD.START_LOADING_RECORD:
            return {
                ...state,
                recordId: action.payload,
                recordData: null,
                loadError: null,
                recordLoading: true
            }
        case ACTIONS.RECORD.SET_LOAD_RECORD_DATA:
            return {
                ...state,
                recordData: action.payload,
                recordStartDateTime: getRecordStartDateTime(action.payload),
                recordLength: getRecordLength(action.payload),
                recordOffset: 0,
                recordSignalWindowWidth: -1,
                recordPaperSpeedIndex: defaultPaperSpeedIndex,
                recordEcgAmpIndex: defaultEcgAmpIndex,
                loadError: null,
                recordProcessing: false,
                recordLoading: false
            }

        case ACTIONS.RECORD.SET_PROGRESS_RECORD_DATA:
            return {
                ...state,
                recordProcessing: true,
                processState: 'processing',
                processCount: state.processCount + 1,
                recordProgress: action.payload
            }

        case ACTIONS.RECORD.SET_LOAD_RECORD_ERROR:
            return {
                ...state,
                recordData: null,
                loadError: action.payload,
                recordLoading: false,
                processState: null,
                processCount: null,
                recordProcessing: false
            }
        case ACTIONS.RECORD.SET_OFFSET:
            return {
                ...state,
                recordOffset: action.payload,
            }

        case ACTIONS.RECORD.SET_INFO_HR_RR:
            return {
                ...state,
                recordInfoHR: action.payload[0],
                recordInfoRR: action.payload[1],
            }
        case ACTIONS.RECORD.SET_PAPER_SPEED_INDEX:
            return {
                ...state,
                recordPaperSpeedIndex: action.payload,
            }
        case ACTIONS.RECORD.SET_ECG_AMP_INDEX:
            return {
                ...state,
                recordEcgAmpIndex: action.payload,
            }
        case ACTIONS.RECORD.SET_TIME_TICK_VISIBILITY:
            return {
                ...state,
                recordTimeTickVisible: action.payload,
            }
        case ACTIONS.RECORD.SET_BEAT_MARKER_VISIBILITY:
            return {
                ...state,
                recordBeatMarkerVisible: action.payload,
            }
        case ACTIONS.RECORD.SET_SIGNAL_WINDOW_WIDTH:
            return {
                ...state,
                recordSignalWindowWidth: action.payload,
            }
        case ACTIONS.RECORD.SET_INFO_PARAM_MODE:
            return {
                ...state,
                recordInfoParamMode: action.payload,
            }

        // process record    
        case ACTIONS.RECORD.START_RECORD_PROCESSING:
            if (state.recordList) {
                return {
                    ...state,
                    recordList: updateRecordData(state.recordList, action.payload, 
                        { rec_proc: false, rec_proc_ok: false, processing: 'starting'}),
                    recordListUpdateCount: state.recordListUpdateCount + 1,  
                } 
            } else {
                return {
                    ...state,
                    recordId: action.payload,
                    recordData: null,
                    recordProcessing: true,
                    recordProgress: null,
                    processState: 'starting',
                    processCount: 0,
                    processRevPolarity: null,
                    processError: null
                }
            }

        case ACTIONS.RECORD.SET_RECORD_PROCESSING_STARTED:
            if (state.recordList) {
                return {
                    ...state,
                    recordList: updateRecordData(state.recordList, action.payload.recordId,
                        { rec_proc: false, rec_proc_ok: false, processing: 'started' }),
                    recordListUpdateCount: state.recordListUpdateCount + 1,
                }
            } else {
                return {
                    ...state,
                    recordProgress: 0,
                    processState: 'started',
                    processCount: 1,
                    processRevPolarity: action.payload.revPolarity
                }
            }
        case ACTIONS.RECORD.SET_RECORD_PROCESSING_ERROR:
            if (state.recordList) {
                return {
                    ...state,
                    recordList: updateRecordData(state.recordList, action.payload,
                        { rec_proc: false, rec_proc_ok: false, processing: 'error', error: action.error }),
                    recordListUpdateCount: state.recordListUpdateCount + 1,
                }
            } else {
                return {
                    ...state,
                    recordData: null,
                    recordProcessing: false,
                    recordProgress: null,
                    processState: 'error',
                    processError: action.error,
                }
            }

        case ACTIONS.RECORD.CLEAR_REV_POLARITY:
            return {
                ...state,
                processRevPolarity: null,
            }

        // annotation update

        case ACTIONS.RECORD.START_ANNOTATION_UPDATE: 
            return {
                ...state,
                updatingStatus: true,
                updateError: null,
            }

        case ACTIONS.RECORD.SET_ANNOTATION_UPDATE_ERROR: 
            return {
                ...state,
                updatingStatus: false,
                updateError: action.error,
            }
        

        case ACTIONS.RECORD.SET_ANNOTATION_UPDATE_OK: {
            const newRecordData = state.recordData;
            newRecordData.mixed_rhythm = action.payload;
            return {
                ...state,
                updatingStatus: false,
                updateError: null,
                recordData: newRecordData,
            }
        }

        case ACTIONS.RECORD.CLEAR_ERROR:
            return {
                ...state,
                updatingStatus: false,
                updateError: null,
            }

        default:
            return state;
    }
   
}
