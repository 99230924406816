import React from 'react';
import {connect} from 'react-redux';
import { useParams } from "react-router-dom";

// import Header from './static_pages/header';
import RecordView from './items/RecordView';
import FooterShort from './static_pages/footer-short';

import { getRecordRequest } from '../redux/requests';

import '../css/main.css';


class RecordPage extends React.Component {
  constructor(props) {
    super(props);
    // this.folder_id = props.match.params.id;
    this.folder_id = props.folder_id;
    this.record_id = props.record_id;
  }

  componentDidMount() {
    if (this.record_id) {
      console.log("==set loading record: ", this.record_id);
      this.props.getRecord(this.record_id, this.folder_id, false);
    } else {
      console.log("error: no record id assigned");
    }
  }

  render() {

    return(
      <div id="mm-0" className="mm-page mm-slideout">
        <div id="page">
          <main>
            <div className='hero_home'>
              <RecordView recordData={this.props.recordData} recordId={this.props.recordId}
                          recordLoading={this.props.recordLoading} recordError={this.props.loadError}/>
            </div>
          </main>

          <FooterShort />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
    return {
      loggedIn: state.auth.userName && state.auth.userToken,
      token: state.record.userToken,
      recordId: state.record.recordId,
      recordData: state.record.recordData,
      recordLoading: state.record.recordLoading,
      loadError: state.record.loadError,
      defaultPage: state.record.defaultPage,
    };
}

function mapDispatchToProps(dispatch) {
    return {
      getRecord: (recordId, token, calc) => dispatch(getRecordRequest(recordId, token, false, calc)),
    };
}


const RecordPageContainer = connect(mapStateToProps, mapDispatchToProps)(RecordPage);


const RecordPageMatch = (props) => {
  let {id, folder_id} = useParams()
  return (<RecordPageContainer record_id={id} folder_id={folder_id} />);
}


export default RecordPageMatch;