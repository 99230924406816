import React from 'react';
import {applyMiddleware, createStore} from "redux";
import { thunk } from "redux-thunk";
import {reducer} from "./redux/reducers";
import {Provider} from "react-redux";
import AppContainer from "./App";
import {logger} from "redux-logger";
// import { LanguageProvider } from './components/languages_pack/containers/Language';

const store = createStore(reducer, applyMiddleware(thunk, logger));

function AppWrapper() {
  return (
    <>
      <Provider store={store}>
        <AppContainer />
      </Provider>
    </>
  );
}

export default AppWrapper;
