import {
    setStartLoadFolderList, setStartLoadRecordList, setStartLoadStatTable, setStartLoadRecord, 
    setRecordProcessingStarting, setRecordProcessingStarted,
    setAnnotationUpdateStarting, setAnnotationUpdateOk, setAnnotationUpdateError,
    setLoadFolderListData, setLoadRecordListData, setLoadStatTableData, setLoadRecordData, setProgressRecordData,
    setLoadFolderListError, setLoadRecordListError, setLoadStatTableError, setLoadRecordError, setRecordProcessingError } from './actions';
import {
    serverUrl, folderListUri, recordListUri, folderStatUri, recordUri, annotationUpdateUri,
    startProcessRecordUri, C_DEFAULT_RECORD_SOURCE_REF, C_DEFAULT_TIME_LENGTH } from '../const/connect_consts';


export const getFolderListRequest = (token) => (dispatch) => {
    console.log("requests: start loading folder list", token);
    dispatch(setStartLoadFolderList(token));

    const url = new URL(serverUrl + folderListUri);

    const req = new XMLHttpRequest();
    req.open("GET", url);
    if (token) {
        req.setRequestHeader("Authorization", `token ${token}`);
    }

    req.onload = (e) => {
        req.onloadend = (event) => {
            if (req.status === 200) {
                const data = JSON.parse(req.responseText);
                // console.log(data);
                dispatch(setLoadFolderListData(data.folders));
            } else {
                dispatch(setLoadFolderListError(`get-folder-list: server response: ${req.status} (${req.statusText}) ${req.responseText}`));
            }
        };
    }

    req.onerror = (e) => {
        console.log(`==Load folders error: ${req.status}`);
        if (req.status === 0) {
            dispatch(setLoadFolderListError("get-folder-list: server response: network error"))
        } else {
            dispatch(setLoadFolderListError(`get-folder-list: server response: ${req.status} - ${req.statusText}`));
        }
    }

    req.send();
}

export const getRecordListRequest = (token, update) => (dispatch) => {
    console.log("requests: start loading record list", token, update);
    if (!update) {
        dispatch(setStartLoadRecordList(token));
    }

    const url = new URL(serverUrl + recordListUri);

    const req = new XMLHttpRequest();
    req.open("GET", url);
    if (token && token.length > 30) {
        req.setRequestHeader("Authorization", `token ${token}`);
    }

    req.onload = (e) => {
        req.onloadend = (event) => {
            if (req.status === 200) {
                const data = JSON.parse(req.responseText);
                // console.log(data);
                dispatch(setLoadRecordListData({ records: data.records, folder: data.folder, hasStat: data.has_stat }));
            } else {
                dispatch(setLoadRecordListError(`get-record-list: server response: ${req.status} (${req.statusText}) ${req.responseText}`));
            }
        };
    }

    req.onerror = (e) => {
        console.log(`==Load record error: ${req.status}`);
        if (req.status === 0) {
            dispatch(setLoadRecordListError("get-record-list: server response: network error"))
        } else {
            dispatch(setLoadRecordListError(`get-record-list: server response: ${req.status} - ${req.statusText}`));
        }
    }

    req.send();
}

export const getFolderStatRequest = (token, update) => (dispatch) => {
    console.log("requests: start loading folder stat", token, update);
    if (!update) {
        dispatch(setStartLoadStatTable(token));
    }

    const url = new URL(serverUrl + folderStatUri);

    const req = new XMLHttpRequest();
    req.open("GET", url);
    if (token) {
        req.setRequestHeader("Authorization", `token ${token}`);
    }

    req.onload = (e) => {
        req.onloadend = (event) => {
            if (req.status === 200) {
                const data = JSON.parse(req.responseText);
                // console.log(data);
                dispatch(setLoadStatTableData({records: data.records, total: data.total, folder: data.folder}));
            } else {
                dispatch(setLoadStatTableError(`get-stat-table: server response: ${req.status} (${req.statusText}) ${req.responseText}`));
            }
        };
    }

    req.onerror = (e) => {
        console.log(`==Load stat table error: ${req.status}`);
        if (req.status === 0) {
            dispatch(setLoadStatTableError("get-stat-table: server response: network error"))
        } else {
            dispatch(setLoadStatTableError(`get-stat-table: server response: ${req.status} - ${req.statusText}`));
        }
    }

    req.send();
}


export const getRecordRequest = (recordId, token, quiet=false, calc=false) => (dispatch) => {
    if (!quiet) {
        dispatch(setStartLoadRecord(recordId));
        console.log("requests: start loading record", recordId, token, "calc", calc);
    }

    const url = new URL(serverUrl + recordUri);
    url.searchParams.set('record_id', recordId);
    url.searchParams.set('time_length', C_DEFAULT_TIME_LENGTH);
    url.searchParams.set('include_org_signal', "1");
    url.searchParams.set('include_rhythm', "1");
    if (calc) {
        // console.log("calc=1");
        url.searchParams.set('calc', "1");
        if (recordId.length > 32) {
            url.searchParams.set('source_ref', C_DEFAULT_RECORD_SOURCE_REF);
        }
    }
    
    const req = new XMLHttpRequest();
    req.open("GET", url);
    if (token) {
        req.setRequestHeader("Authorization", `token ${token}`);
    }

    req.onload = (e) => {
        req.onloadend = (event) => {
            // console.log(req);
            if (req.status === 200) {
                const data = JSON.parse(req.responseText);
                if (data.status===0) {
                    dispatch(setLoadRecordData(data.record));
                } else 
                if (data.status===1) {
                    console.log(data);
                    dispatch(setProgressRecordData(data.progress));
                } else {
                    dispatch(setLoadRecordError(`get-record: status=${data.status}`));
                }
            } else {
                dispatch(setLoadRecordError(`get-record: server response: ${req.status} (${req.statusText}) \n ${req.responseText.substring(0, 200)}`));
            }
        };
    }

    req.onerror = (e) => {
        console.log(`==Load record error: ${req.status}`);
        if (req.status === 0) {
            dispatch(setLoadRecordError("get-record: server response: network error"))
        } else {
            dispatch(setLoadRecordError(`get-record: server response: ${req.status} - ${req.statusText}`));
        }
    }

    req.send();
}

export const runProcessRecordRequest = (recordId, revPolarity, token) => (dispatch) => {
    console.log("requests: starting run process record", recordId, token);
    dispatch(setRecordProcessingStarting(recordId));
    console.log("requests: start run process record", recordId, token);

    const url = new URL(serverUrl + startProcessRecordUri);
    const req = new XMLHttpRequest();
    req.open("POST", url);
    req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    if (token && token.length > 30) {
        req.setRequestHeader("Authorization", `token ${token}`);
    }

    req.onload = (e) => {
        req.onloadend = (event) => {
            // console.log(req);
            if (req.status === 200) {
                dispatch(setRecordProcessingStarted(recordId, revPolarity));
            } else {
                dispatch(setRecordProcessingError(recordId, `run-process-record: server response: ${req.status} (${req.statusText}) ${req.responseText}`));
            }
        };
    }

    req.onerror = (e) => {
        console.log(`==Run process record error: ${req.status}`);
        if (req.status === 0) {
            dispatch(setRecordProcessingError(recordId, "run-process-record: server response: network error"))
        } else {
            dispatch(setRecordProcessingError(recordId, `run-process-record: server response: ${req.status} - ${req.statusText}`));
        }
    }

    const rqData = {
        record_id: recordId
    }
    if (recordId.length > 32) {
        rqData["source_ref"] = C_DEFAULT_RECORD_SOURCE_REF;
        
    }
    if (revPolarity) {
        rqData["rev_polarity"] = "1";
    }

    req.send(JSON.stringify(rqData));
}


export const annotationUpdateRequest = (recordId, token, code, start, finish, isClear) => (dispatch) => {
    console.log("requests: starting annotation update", recordId, token, code);
    dispatch(setAnnotationUpdateStarting(recordId));

    const url = new URL(serverUrl + annotationUpdateUri);
    const req = new XMLHttpRequest();
    req.open("POST", url);
    req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    if (token) {
        req.setRequestHeader("Authorization", `token ${token}`);
    }

    req.onload = (e) => {
        req.onloadend = (event) => {
            // console.log(req);
            if (req.status === 200) {
                const data = JSON.parse(req.responseText);
                if (data.status==="ok") {
                    dispatch(setAnnotationUpdateOk(data.mixed_rhythm));
                } else {
                    dispatch(setAnnotationUpdateError(recordId, `annotation-update: status: ${data.status}`));
                }
            } else {
                dispatch(setAnnotationUpdateError(recordId, `annotation-update: server response: ${req.status} (${req.statusText}) ${req.responseText}`));
            }
        };
    }

    req.onerror = (e) => {
        console.log(`==annotation-update error: ${req.status}`);
        if (req.status === 0) {
            dispatch(setAnnotationUpdateError(recordId, "annotation-update error: server response: network error"))
        } else {
            dispatch(setAnnotationUpdateError(recordId, `annotation-update error: server response: ${req.status} - ${req.statusText}`));
        }
    }

    const rqData = {
        record_id: recordId,
        ranges: [{t: code, s: start, r: finish-start, m: 1, c: isClear? 1 : 0}]
    }
    req.send(JSON.stringify(rqData));


}