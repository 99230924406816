import React from 'react';
import { Link } from "react-router-dom";

import InfoPage from './InfoPage';
import FolderTable from './FolderTable';

function getInfoKind(loading) {
  if (loading) {
    return "info";
  }
  return "danger";
}

function getInfoMessage(loading, error) {
  if (loading) {
    return `Folder list loading in progress ...`;
  }
  if (error) {
    return `Folder list load error: ${error}`;
  }
  return `Folder list load error: Unknown`;
}

const getFolderHeader = (folder_id, folder, hasStat) => {
  const statCell = hasStat ? (<div>
    <Link className="btn btn-outline-secondary" to={`/folder/${folder_id}/stat`}>Recognition statictics</Link>
  </div>) : null;
  return (
    <div className="folder-name row">
      <div className="mt-1">
        <label>Folder</label>
      </div>
      <div className="mt-1">
        <label><b>{folder}</b></label>
      </div>
      {statCell}
    </div>
  );

}


const FolderList = (props) => {

  const { folderList, loading, loadError } = props;
  // console.log(props);

  if (!folderList) {
    return (
      <main>
        <a href="#top" name="top" >&nbsp;</a>
        <div className='hero_home'>
          <div className='content'>
            <InfoPage message={getInfoMessage(loading, loadError)} kind={getInfoKind(loading)} />
          </div>
        </div>
      </main>

    );
  }

  const folderTable = folderList ? (<FolderTable table={folderList} />) : null;
  const folderHeader = getFolderHeader(null, "Root", null);

  return (
    <main>
      <div className='hero_home'>
        <div className='content-record'>
          {folderHeader}
          {folderTable}
        </div>
      </div>
    </main>
  )

}


export default FolderList;