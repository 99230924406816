import React from 'react';

import { Link } from "react-router-dom";

import InfoPage from './InfoPage';
import FolderTrainTable from './FolderTrainTable';

function getInfoKind(loading) {
  if (loading) {
    return "info";
  }
  return "danger";
}

function getInfoMessage(loading, error) {
  if (loading) {
    return `Folder stat loading in progress ...`;
  }
  if (error) {
    return `Folder stat load error: ${error}`;
  }
  return `Folder stat load error: Unknown`;
}

const getFolderHeader = (folder_id, folder, total) => {
  return (
    <div className="folder-name d-flex">
      <label className="my-auto">Folder</label>
      <div>
        <Link className="btn btn-outline-secondary" to={`/folder/${folder_id}`}>{folder}</Link>
      </div>
      <div className="mt-1">
        <label>accuracy=</label>
        <label><b>{total.acc}%</b></label>
      </div>
      <div className="mt-1">
        <label>precision=</label>
        <label><b>{total.prec}%</b></label>
      </div>
      <div className="mt-1">
        <label>sensitivity=</label>
        <label><b>{total.tp_rate}%</b></label>
      </div>
      <div className="mt-1">
        <label>specificity=</label>
        <label><b>{total.tn_rate}%</b></label>
      </div>
    </div>

  );

}


const FolderTrain = (props) => {

  const { folderStatTable, folderStatTotal, folder, folder_id, folderStatLoading, loadError } = props;
  console.log(props);

  if (!folderStatTable) {
    return (
      <main>
        <a href="#top" name="top" >&nbsp;</a>
        <div className='hero_home'>
          <div className='content'>
            <InfoPage message={getInfoMessage(folderStatLoading, loadError)} kind={getInfoKind(folderStatLoading)} />
          </div>
        </div>
      </main>

    );
  }

  const folderHeader = folder ? getFolderHeader(folder_id, folder, folderStatTotal) : getFolderHeader("Anonymous records", folderStatTotal);

  return (
    <main>
      <div className='hero_home'>
        <div className='content-record'>
          {folderHeader}
          <FolderTrainTable table={folderStatTable} total={folderStatTotal} folder_id={folder_id}/>
        </div>
      </div>
    </main>
  )

}


export default FolderTrain;