import React from 'react';  // , { useState}

import InfoPage from './InfoPage';

import SignalInfoItem from './SignalInfoItem';
import SignalControlItem from './SignalControlItem';

import TimeInfoItem from './TimeInfoItem';
import RRRowItem from './RRRowItem';

function getInfoMessage(loading, error, recordId=null) {
  if (loading) {
    return `Record "${recordId}" loading in progress ...`;
  }
  if (error) {
    return `Record "${recordId}" load error: ${error}`;
  }
  return `Record "${recordId}" load error: Unknown`;
}

function getInfoKind(loading) {
  if (loading) {
    return "info";
  }
  return "danger";
}

const RecordView = (props) => {

  const {recordData, recordLoading, recordError, recordId} = props;

  if (!recordData) {
    return (
          <div className='content'>
            <InfoPage message={getInfoMessage(recordLoading, recordError, recordId)} kind={getInfoKind(recordLoading)}/>
          </div>
    );
  }

  const { proc_signal, frequency } = recordData;  

  if (!proc_signal) {
      return (
          <div className='content'>
            <InfoPage message="record has no field: proc_signal" kind="danger" />
          </div>
      )
    }

  if (!frequency) {
      return (
          <div className='content'>
             <InfoPage message="record has no field: frequency" kind="danger" />
          </div>
      )
  }

  return(
      <div className='content-record'>
        <div className='ecg-row-container'>
          <SignalInfoItem />
          <SignalControlItem />
        </div>
        <div className='ecg-row-container'>
          <TimeInfoItem />
          <RRRowItem />
        </div>
      </div>
    )
}


export default RecordView;
