import React from 'react';

import "../../css/info-page.css";

const ProgressPage = (props) => {
    let { message, kind, progress, recalcHandler, cancelHandler } = props;
    if (!kind) kind = "info";

    console.log(progress);
    const bar = progress ? (
      <div className="progress mb-2" style={{ height: "60px" }}>
        <div className="progress-bar bg-info" role="progressbar"
            style={{ width: `${progress}%`, ariaValuenow: progress, ariaValuemin: 0, ariaValuemax: 100 }}>
        </div>
       </div> ) : null;

    const buttons = progress || kind === 'info'  ? (
        <div>
            <button className="btn btn-outline-secondary ml-2" onClick={cancelHandler}>Cancel</button>
        </div>
    ) : (
        <div>
            <button className="btn btn-outline-dark mr-2" onClick={recalcHandler}>Recalculate</button>
            <button className="btn btn-outline-secondary ml-2" onClick={cancelHandler}>Cancel</button>
        </div>
    );

    return (
        <div className='info-page'>
            <div className={`alert alert-${kind}`} role="alert">
                {message}
            </div>
            {bar}
            {buttons}
        </div>
    )
}


export default ProgressPage;
