import React from "react";


const ButtonsControl = (props) => {
  const { left, onStart, onPageBack, onStepBack, onStepFwd, onPageFwd, onFinish } = props;
  return (
   <div className="btn-group btn-group-sm2 x-control" role="group" aria-label="Button group with nested dropdown" style={{left: left}}>
      <label type="button" className="btn btn-outline-primary btn-sm2 " onClick={onStart}><i className="fa fa-step-backward"></i></label>
      <label type="button" className="btn btn-outline-primary btn-sm2 " onClick={onPageBack}><i className="fa fa-backward"></i></label>
      <label type="button" className="btn btn-outline-primary btn-sm2 " onClick={onStepBack}><i className="fa fa-chevron-left"></i></label>
      <label type="button" className="btn btn-outline-primary btn-sm2 " onClick={onStepFwd}><i className="fa fa-chevron-right"></i></label>
      <label type="button" className="btn btn-outline-primary btn-sm2 " onClick={onPageFwd}><i className="fa fa-forward"></i></label>
      <label type="button" className="btn btn-outline-primary btn-sm2 " onClick={onFinish}><i className="fa fa-step-forward"></i></label>
   </div>  
  ); 
}

export default ButtonsControl;