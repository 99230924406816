import React from 'react';


export default class FooterShort extends React.Component {
  render() {
    return(
      <footer style={{backgroundColor: "#f5f8fa"}}>
        <div className="container margin_10_35">
        </div>
      </footer>
    )
  }
}

