import React from 'react';
import { connect } from 'react-redux';

import { setPaperSpeedIndex, setEcgAmpIndex, setTimeTickVisibility, 
  setBeatMarkerVisibility, setInfoParamMode } from '../../redux/actions';

import {
  MV1_COLOR, DISABLE_COLOR,
  PAPER_SPEED_LIST, MAX_PAPER_SPEED_INDEX, ECG_AMP_LIST, MAX_ECG_AMP_INDEX, 
  INFO_PARAM_MODE_COUNT, INFO_PARAM_LABELS, INFO_PARAM_UNITS, INFO_PARAM_WIDTHS } from "../../const/signal_consts";

import IconCircle from "../tools/icon-circle";
import ButtonsLfRt from "../tools/btns-lfrt";
import ButtonsUpDown from "../tools/btns-updn";
import "../../css/ecg-info.css";

const SignalInfoItem = (props) => {
  const { infoHR, infoRR,
    infoParamMode, setInfoParamMode,
    paperSpeedIndex, paperSpeedStr, setPaperSpeedIndex, 
    ecgAmpIndex, ecgAmpStr, setEcgAmpIndex,
    timeTickVisible, setTimeTickVisible,
    beatMarkerVisible, setBeatMarkerVisible } = props ;

  const onIncPaperSpeedIndex = () => {
    if (paperSpeedIndex < MAX_PAPER_SPEED_INDEX) {
      setPaperSpeedIndex(paperSpeedIndex + 1);
    }
  }

  const onDecPaperSpeedIndex = () => {
    if (paperSpeedIndex > 0) {
      setPaperSpeedIndex(paperSpeedIndex - 1);
    }
  }

  const onIncEcgAmpIndex = () => {
    if (ecgAmpIndex < MAX_ECG_AMP_INDEX) {
      setEcgAmpIndex(ecgAmpIndex + 1);
    }
  }

  const onDecEcgAmpIndex = () => {
    if (ecgAmpIndex > 0) {
      setEcgAmpIndex(ecgAmpIndex - 1);
    }
  }

  const onToggleTimeTickVisible = () => {
    setTimeTickVisible(!timeTickVisible);
  }

  const onToggleBeatMarkerVisible = () => {
    setBeatMarkerVisible(!beatMarkerVisible);
  }

  const onToggleInfoParamMode = () => {
    setInfoParamMode((infoParamMode+1) % INFO_PARAM_MODE_COUNT);
  }

  const infoParamValues = { 
    0: infoHR ? infoHR : "---", 
    1: infoRR ? infoRR: "---", 
  }

  const infoParamTitle = INFO_PARAM_LABELS[infoParamMode];
  const infoParamValue = infoParamValues[infoParamMode];
  const infoParamUnit = INFO_PARAM_UNITS[infoParamMode];
  const infoParamWidth = INFO_PARAM_WIDTHS[infoParamMode];
 
  const iconMarkerSwitch = IconCircle(beatMarkerVisible ? "rgb(1, 194, 169)" : DISABLE_COLOR);
  const iconTimeSwitch = IconCircle(timeTickVisible ? MV1_COLOR : DISABLE_COLOR);
  
  return(
      <div className="ecg-info-panel">
        <div className="ecg-top-info row">
          <div className="ecg-par" onClick={onToggleInfoParamMode}>
            <span className="par-title">{infoParamTitle}</span>
            <span className="par-value" style={{ minWidth: infoParamWidth }}>{infoParamValue}</span>
            <span className="par-unit">{infoParamUnit}</span>
          </div>
          <div className="ecg-info-switch-marker" onClick={onToggleBeatMarkerVisible}>
            {iconMarkerSwitch}
          </div>

        </div>
        <div className="ecg-channel row">
          <h2 className="channel-title">ECG channel</h2>
        </div>

        <div className="ecg-amp-control">
          <ButtonsUpDown value={ecgAmpStr} onIncIndex={onIncEcgAmpIndex} onDecIndex={onDecEcgAmpIndex} />
        </div>

        <div className="ecg-speed-control">
          <ButtonsLfRt value={paperSpeedStr} onIncIndex={onIncPaperSpeedIndex} onDecIndex={onDecPaperSpeedIndex} />
        </div>

        <div className="ecg-info-switch-time" onClick={onToggleTimeTickVisible}>
          {iconTimeSwitch}
        </div>

      </div>
    )
}


function mapStateToProps(state) {
  return {
    infoHR: state.record.recordInfoHR,
    infoRR: state.record.recordInfoRR,
    paperSpeedIndex: state.record.recordPaperSpeedIndex,
    paperSpeedStr: `${PAPER_SPEED_LIST[state.record.recordPaperSpeedIndex]} mm/s`,
    ecgAmpIndex: state.record.recordEcgAmpIndex,
    ecgAmpStr: `${ECG_AMP_LIST[state.record.recordEcgAmpIndex]} mm/mV`,
    timeTickVisible: state.record.recordTimeTickVisible,
    beatMarkerVisible: state.record.recordBeatMarkerVisible,
    infoParamMode: state.record.recordInfoParamMode  
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPaperSpeedIndex: (paperSpeedIndex) => dispatch(setPaperSpeedIndex(paperSpeedIndex)),
    setEcgAmpIndex: (ecgAmpIndex) => dispatch(setEcgAmpIndex(ecgAmpIndex)),
    setTimeTickVisible: (value) => dispatch(setTimeTickVisibility(value)),
    setBeatMarkerVisible: (value) => dispatch(setBeatMarkerVisibility(value)),
    setInfoParamMode: (value) => dispatch(setInfoParamMode(value))
  };
}


const SignalInfoContainer = connect(mapStateToProps, mapDispatchToProps)(SignalInfoItem);
export default SignalInfoContainer;
