import React from 'react';
import { connect } from 'react-redux';

import { PAPER_CELL_WIDTH, PAPER_CELL_HEIGHT, PAPER_SPEED_LIST, SIGNAL_COLOR } from "../../const/signal_consts";
import { setRecordOffset } from '../../redux/actions';
import { getTickByX } from "../tools/calc-funcs";

const PAGE_SIZE = 60*8;

const findFirstBeatIndex = (beats, offset) => {
  if (!beats) {
    return -1;
  }
  let i = 1;
  
  while (i < beats.length) {
    const b = beats[i];
    if (b.r > offset) {
      return i;
    }
    i++;
  }
  return -1;
}

const calcTickByX = (x, w, offset, frequency) => {
  const tPage = frequency * PAGE_SIZE; 
  const nPage = Math.max(0, Math.round(4 * offset / tPage) - 3);
  const tOffset = Math.round(nPage * tPage / 4);
 
  return Math.round(x / w * tPage + tOffset);
}

const makeRRRow = (beats, recordLength, offset, frequency, paperSpeed, w, h = PAPER_CELL_HEIGHT) => {
  const tPage = frequency * PAGE_SIZE; 
  const nPage = Math.max(0, Math.round(4*offset / tPage) - 3);
  const pOffset = Math.round(nPage * tPage / 4);
  

  const lc = SIGNAL_COLOR;
  const z = h - 1;

  let cells = [];

  const mm_x = PAPER_CELL_WIDTH / 10;
  const tw = getTickByX(w, paperSpeed, frequency, mm_x); 

  const xs = Math.round((offset - pOffset) * w / tPage);
  const xd = Math.round(tw * w / tPage);
  const selectedRect = (<rect key={0} x={xs} y={0} width={xd} height={h} stroke="transparent" fill="blue" fillOpacity="0.1" />);
  cells.push(selectedRect);

  if (recordLength - pOffset < tPage) {
    const xs = Math.round((recordLength - pOffset) * w / tPage);
    const xd = w - xs;
    const offRect = (<rect key={1} x={xs} y={0} width={xd} height={h} stroke="transparent" fill="gray" fillOpacity="0.1" />);
    cells.push(offRect);
  }

  let bi = findFirstBeatIndex(beats, pOffset);
  if (bi > 0) {
    while (bi < beats.length) {
      const b = beats[bi];
      const bt = b.r - pOffset;
      if (bt > tPage) break;

      const lx = Math.round((bt) * w / tPage);
      const ly = z - Math.round(b.rr * h / (1.8 * frequency));
      cells.push((<line key={cells.length} x1={lx} y1={ly} x2={lx} y2={z} style={{ stroke: lc, strokeWidth: 1 }} />));

      bi++;
    }

  }
  return (<svg xmlns="http://www.w3.org/2000/svg" width={w} height={h}> {cells}</svg>);  
}

const RRRowItem = (props) => {
  const { recordData, recordLength, offset, setRecordOffset, paperSpeed, signalWindowWidth } = props ;
  const { beats, frequency }  = recordData;  

  const onRRRowClick = (event) => {
    //console.log(event.clientX, event.target.offsetLeft);
    const tPage = Math.round(signalWindowWidth * 10 / (paperSpeed * PAPER_CELL_WIDTH));
    const t = Math.max(0, calcTickByX(Math.max(0, event.clientX - 200), signalWindowWidth, offset, frequency) - Math.round(tPage * frequency / 2));
    // console.log(t);
    setRecordOffset(Math.min(t, recordLength - (tPage - 2)*frequency));
  }

  const svgRRRow = signalWindowWidth > 10 ? makeRRRow(beats, recordLength, offset, frequency, paperSpeed, signalWindowWidth) : null;

  return(
      <div className="rr-row-panel" onClick={onRRRowClick}>
         {svgRRRow}
      </div>
    )
}


function mapStateToProps(state) {
  return {
    recordData: state.record.recordData,
    recordLength: state.record.recordLength,
    offset: state.record.recordOffset,
    signalWindowWidth: state.record.recordSignalWindowWidth,
    paperSpeed: PAPER_SPEED_LIST[state.record.recordPaperSpeedIndex],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setRecordOffset: (offset) => dispatch(setRecordOffset(offset)),    
  };
}


const RRRowContainer = connect(mapStateToProps, mapDispatchToProps)(RRRowItem);
export default RRRowContainer;
