export const PAPER_CELL_WIDTH = 42;
export const PAPER_CELL_HEIGHT = 43;

export const SIGNAL_COLOR = "#00194b";
export const MV1_COLOR = "#A0A0C0";
export const DISABLE_COLOR = "#D0D0E0";

export const AMP_LINE_MAX_MKV = 1200;

export const PAPER_SPEED_LIST = {0: 12.5, 1: 25, 2: 50, 3: 100};
export const defaultPaperSpeedIndex = 1;
export const MAX_PAPER_SPEED_INDEX = 3;
export const C_DEFAULT_PAPED_SPEED_INDEX = 1;

export const ECG_AMP_LIST = { 0: 5, 1: 10, 2: 20, 3: 30, 4: 40};
export const defaultEcgAmpIndex = 1;
export const MAX_ECG_AMP_INDEX = 4;
export const C_DEFAULT_SIGNAL_GAIN_INDEX = 2;

export const INFO_PARAM_MODE_COUNT = 2;
export const INFO_PARAM_LABELS = { 0: 'HR', 1:'RR' };
export const INFO_PARAM_UNITS = { 0: 'BPM', 1: 'ms' };
export const INFO_PARAM_WIDTHS = { 0: "52px", 1: '60px' };

export const localeStr = 'en-US';
