/*eslint-disable*/
import React from "react";

const IconCircle = (color="black") => {

    return (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
           <g>
            <circle cx="9" cy="9" r="6" stroke={color} />
            <circle cx="9" cy="9" r="2" fill={color} stroke={color} />
           </g>              
          </svg>
    )
}

export default IconCircle;
/*eslint-enable*/
