export function getTickByX(x, paperSpeed, frequency, mm_x) {
    return Math.floor(x * frequency / (mm_x * paperSpeed) );
}

export function getXByTick(t, offset, paperSpeed, frequency, mm_x) {
    return Math.floor((t - offset) * mm_x * paperSpeed / frequency);;
}

export const RSI_REGULAR_SINUS = 0x10;
export const RSI_TA_SINUS = 0x11;
export const RSI_BRA_SINUS = 0x12;
export const RSI_REGULAR_RIGID = 0x13;
export const RSI_ARRHYTHMIA_SINUS = 0x14;

export const RSI_SUPRAVENTRICULAR = 0x20;
export const RSI_VENTRICULAR = 0x45;
export const RSI_EXTRASYSTOLE = 0x50;

export const RSI_AFIB = 0x41;
export const RSI_ATRIAL_FLATTER = 0x43;


const BEAT_PALETTE = {
    0: "#E5E1D6", // gray
    1: "#C0FFC0",  // green
    2: "#CEDFB5",  // gray-green  
    3: "#FFC0C0",  // red  sv

}

const RHYTHM_PALETTE = { 
    0: "#CCCCC4", // gray
    [RSI_REGULAR_SINUS]: "#AFF8DB",  // green
    [RSI_TA_SINUS]: "#DBFFD6",  // light-green
    [RSI_BRA_SINUS]: "#AFCBFF",  // light blue
    [RSI_REGULAR_RIGID]: "#6EB5FF",  // blue
    
    [RSI_ARRHYTHMIA_SINUS]: "#E7FFAC",  // yellow

    [RSI_EXTRASYSTOLE]: "#FFABAB", // rose

    [RSI_AFIB]: "#FF9CEE",  // magenta
};

export function getRhythmColorByCode(code) {
    return code in RHYTHM_PALETTE ? RHYTHM_PALETTE[code] : RHYTHM_PALETTE[0];
}

export function getRhythmColor(t, rhythm) {
    if (!rhythm) {
        return null;    
    }

    let c = RHYTHM_PALETTE[0];  // grey
    let m = false;
    rhythm.every(item => {
        if ((item.s < t) && (t <= item.s + item.r)) {
            // console.log(RHYTHM_PALETTE, item);
            c = getRhythmColorByCode(item.t);
            m = item.m > 0? true : false;
            return false;
        }
        return true;
    });
    return [c, m];
}

export function getRhythmCode(t, rhythm) {
    if (!rhythm) {
        return null;
    }

    let c = 0;  // undefined
    rhythm.every(item => {
        if ((item.s < t) && (t <= item.s + item.r)) {
            c = item.t;
            return false;
        }
        return true;
    });
    return c;
}

export function getBeatColor(b) {
    if ((b.cd === 4) || (b.cd === "4")) return BEAT_PALETTE[0];
    if ((b.cd === 5) || (b.cd === "5")) return BEAT_PALETTE[3];
    return b.rj && b.q1r ? BEAT_PALETTE[1] : BEAT_PALETTE[2];
}


export function getUserBeatColor(b, beats, diff=4) {
    const bs = beats.find(bb => Math.abs(bb.r0 - b.r0) <= diff);
    return bs ? "#00A000" : "#E00000";
}


export function checkUserRange(rhythm) {
    const resp = rhythm ? rhythm.find(r=>r.m > 0) : null;
    return resp ? true : false;
}

export function hasRangeIntersection(r1, r2) {
    const [rs1, rf1] = r1;
    const [rs2, rf2] = r2;
    // console.log("hasRangeIntersection", r1, r2);
    return (rs1 <= rs2 && rs2 <= rf1) || (rs2 <= rs1 && rs1 <= rf2);
}

function toSec(v, frequency) {
    return Math.round(1000 * v / frequency) / 1000;
}

export function checkRangeAndRhythmIntersection(range, rhythm, frequency) {
    // console.log("checkRangeAndRhythmIntersection", range, rhythm);
    const resp = rhythm ? rhythm.find(r => r.m > 0 && hasRangeIntersection([toSec(r.s, frequency), toSec(r.s + r.r, frequency)], [range.start, range.finish])) : null;
    return resp ? true : false;
}

export function a1000(s) {
    return s.length > 3 ? s.substring(0, s.length - 3) + " " + s.substring(s.length - 3, s.length) : s; 
}

export function toMSstr(s) {
    const ms = Math.round(1000*s);
    return a1000('' + ms);
}