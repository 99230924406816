export const C_PAGE_REVIEW = 0;
export const C_PAGE_RHYTHM = 1;
export const C_PAGE_INTERVAL = 2; 

export const C_PAGES = [C_PAGE_REVIEW, C_PAGE_RHYTHM, C_PAGE_INTERVAL];


export const ACTIONS = {
    AUTH: {
        UPDATE_LOGIN: 'update_login',
        UPDATE_PASSWORD: 'update_password',
        LOG_IN: 'log_in',
        LOG_OUT: 'log_out',
        REQUEST_START: 'start_auth_request',
        START_SEND_USER_FORM: 'START_SEND_USER_FORM',
        SET_USER_FORM_MESSAGE: 'SET_USER_FORM_MESSAGE',
        SET_USER_FORM_ERROR: 'SET_USER_FORM_ERROR',
        SET_USER_DATA: 'SET_USER_DATA',
        CLEAR_USER_DATA: 'CLEAR_USER_DATA',
        REDIRECT: 'REDIRECT'
    },
    RECORD: {
      START_LOADING_FOLDER_LIST: 'START_LOADING_FOLDER_LIST',
      SET_LOAD_FOLDER_LIST_DATA: 'SET_LOAD_FOLDER_LIST_DATA',
      SET_LOAD_FOLDER_LIST_ERROR: 'SET_LOAD_FOLDER_LIST_ERROR',

      START_LOADING_RECORD_LIST: 'START_LOADING_RECORD_LIST',
      SET_LOAD_RECORD_LIST_DATA: 'SET_LOAD_RECORD_LIST_DATA',
      SET_LOAD_RECORD_LIST_ERROR: 'SET_LOAD_RECORD_LIST_ERROR',

      START_LOADING_STAT_TABLE: 'START_LOADING_STAT_TABLE',
      SET_LOAD_STAT_TABLE_DATA: 'SET_LOAD_STAT_TABLE_DATA',
      SET_LOAD_STAT_TABLE_ERROR: 'SET_LOAD_STAT_TABLE_ERROR',

      START_LOADING_RECORD: 'START_LOADING_RECORD',
      SET_LOAD_RECORD_DATA: 'SET_LOAD_RECORD_DATA',
      SET_PROGRESS_RECORD_DATA: 'SET_PROGRESS_RECORD_DATA',
      SET_LOAD_RECORD_ERROR: 'SET_LOAD_RECORD_ERROR',

      START_RECORD_PROCESSING: 'START_RECORD_PROCESSING',
      SET_RECORD_PROCESSING_STARTED: 'SET_RECORD_PROCESSING_STARTED',
      SET_RECORD_PROCESSING_ERROR: 'SET_RECORD_PROCESSING_ERROR',

      START_ANNOTATION_UPDATE: 'START_ANNOTATION_UPDATE',
      SET_ANNOTATION_UPDATE_OK: 'SET_ANNOTATION_UPDATE_OK',
      SET_ANNOTATION_UPDATE_ERROR: 'SET_ANNOTATION_UPDATE_ERROR',

      SET_OFFSET: 'SET_OFFSET',
      SET_INFO_HR_RR: 'SET_INFO_HR_RR',
      SET_PAPER_SPEED_INDEX: 'SET_PAPER_SPEED_INDEX',
      SET_ECG_AMP_INDEX: 'SET_ECG_AMP_INDEX',
      SET_TIME_TICK_VISIBILITY: 'SET_TIME_TICK_VISIBILITY',
      SET_BEAT_MARKER_VISIBILITY: 'SET_BEAT_MARKER_VISIBILITY',
      SET_SIGNAL_WINDOW_WIDTH: 'SET_SIGNAL_WINDOW_WIDTH',
      SET_INFO_PARAM_MODE: 'SET_INFO_PARAM_MODE',
      CLEAR_REV_POLARITY: 'CLEAR_REV_POLARITY',

      CLEAR_ERROR: 'CLEAR_ERROR'
  }
}

export const setRedirect = (uri) => {
  return{
    type: ACTIONS.AUTH.REDIRECT,
    payload: uri
  }
}

export const setStartLoadRecord = (recordId) => {
  return {
    type: ACTIONS.RECORD.START_LOADING_RECORD,
    payload: recordId
  }
}

export const setRecordProcessingStarting = (recordId) => {
  return {
    type: ACTIONS.RECORD.START_RECORD_PROCESSING,
    payload: recordId
  }
}

export const setAnnotationUpdateStarting = (recordId) => {
  return {
    type: ACTIONS.RECORD.START_ANNOTATION_UPDATE,
    payload: recordId
  }
}

export const setRecordProcessingStarted = (recordId, revPolarity=false) => {
  return {
    type: ACTIONS.RECORD.SET_RECORD_PROCESSING_STARTED,
    payload: {recordId, revPolarity}

  }
}

export const setStartLoadRecordList = (token) => {
  return {
    type: ACTIONS.RECORD.START_LOADING_RECORD_LIST,
    payload: token
  }
}

export const setStartLoadFolderList = (token) => {
  return {
    type: ACTIONS.RECORD.START_LOADING_FOLDER_LIST,
    payload: token
  }
}


export const setStartLoadStatTable = (token) => {
  return {
    type: ACTIONS.RECORD.START_LOADING_STAT_TABLE,
    payload: token
  }
}


export const setLoadRecordError = (err) => {
  return {
    type: ACTIONS.RECORD.SET_LOAD_RECORD_ERROR,
    payload: err
  }
}

export const setLoadFolderListError = (err) => {
  return {
    type: ACTIONS.RECORD.SET_LOAD_FOLDER_LIST_ERROR,
    payload: err
  }
}


export const setLoadRecordListError = (err) => {
  return {
    type: ACTIONS.RECORD.SET_LOAD_RECORD_LIST_ERROR,
    payload: err
  }
}

export const setLoadStatTableError = (err) => {
  return {
    type: ACTIONS.RECORD.SET_LOAD_STAT_TABLE_ERROR,
    payload: err
  }
}

export const setRecordProcessingError = (recordId, err) => {
  return {
    type: ACTIONS.RECORD.SET_RECORD_PROCESSING_ERROR,
    payload: recordId,
    error: err
  }
}

export const setAnnotationUpdateError = (recordId, err) => {
  return {
    type: ACTIONS.RECORD.SET_ANNOTATION_UPDATE_ERROR,
    payload: recordId,
    error: err
  }
}

export const setLoadRecordData = (record) => {
  return {
    type: ACTIONS.RECORD.SET_LOAD_RECORD_DATA,
    payload: record
  }
}


export const setAnnotationUpdateOk = (data) => {
  return {
    type: ACTIONS.RECORD.SET_ANNOTATION_UPDATE_OK,
    payload: data
  }
}

export const setProgressRecordData = (progress) => {
  return {
    type: ACTIONS.RECORD.SET_PROGRESS_RECORD_DATA,
    payload: progress
  }
}

export const setLoadFolderListData = (data) => {
  return {
    type: ACTIONS.RECORD.SET_LOAD_FOLDER_LIST_DATA,
    payload: data
  }
}

export const setLoadRecordListData = (data) => {
  return {
    type: ACTIONS.RECORD.SET_LOAD_RECORD_LIST_DATA,
    payload: data
  }
}

export const setLoadStatTableData = (data) => {
  return {
    type: ACTIONS.RECORD.SET_LOAD_STAT_TABLE_DATA,
    payload: data
  }
}

export const setRecordOffset = (offset) => {
  return {
    type: ACTIONS.RECORD.SET_OFFSET,
    payload: offset
  }
}

export const setInfoHR_RR = (hr, rr) => {
  return {
    type: ACTIONS.RECORD.SET_INFO_HR_RR,
    payload: {0: hr, 1: rr}
  }
}

export const setPaperSpeedIndex = (paperSpeedIndex) => {
  return {
    type: ACTIONS.RECORD.SET_PAPER_SPEED_INDEX,
    payload: paperSpeedIndex
  }
}

export const setEcgAmpIndex = (ecgAmpIndex) => {
  return {
    type: ACTIONS.RECORD.SET_ECG_AMP_INDEX,
    payload: ecgAmpIndex
  }
}

export const setTimeTickVisibility = (value) => {
  return {
    type: ACTIONS.RECORD.SET_TIME_TICK_VISIBILITY,
    payload: value
  }
}

export const setBeatMarkerVisibility = (value) => {
  return {
    type: ACTIONS.RECORD.SET_BEAT_MARKER_VISIBILITY,
    payload: value
  }
}

export const setSignalWindowWidth = (value) => {
  return {
    type: ACTIONS.RECORD.SET_SIGNAL_WINDOW_WIDTH,
    payload: value
  }
}

export const setInfoParamMode = (value) => {
  return {
    type: ACTIONS.RECORD.SET_INFO_PARAM_MODE,
    payload: value
  }
}

export const clearRevPolarityAction = () => {
  return {
    type: ACTIONS.RECORD.CLEAR_REV_POLARITY
  }
}

export const clearRecordErrorAction = (recordId) => {
  return {
    type: ACTIONS.RECORD.CLEAR_ERROR
  }  
}
