import {ACTIONS} from './actions';

export const initialStateAuth = {
  redirect: null,
  getRegisterError: null,
  registerMessage: false,
  userToken: null, //localStorage.getItem("u_token"),
  userName:  localStorage.getItem("u_name")
}

export function auth(state = initialStateAuth, action) {
    switch (action.type) {
        case ACTIONS.AUTH.START_SEND_USER_FORM:
          return {
            ...state,
            getRegisterError: null,
            registerMessage: false,
            // loggedIn: false
          }
        case ACTIONS.AUTH.SET_USER_FORM_MESSAGE:
          return {
            ...state,
            registerMessage: true,
          }
        case ACTIONS.AUTH.SET_USER_FORM_ERROR:
          return {
            ...state,
            registerMessage: false,
            getRegisterError: action.payload,
          }
        case ACTIONS.AUTH.SET_USER_DATA:
          localStorage.setItem("u_token", action.payload[0]);
          localStorage.setItem("u_name", action.payload[1])
          return {
            ...state,
            userToken: action.payload[0],
            userName: action.payload[1],
          }
        case ACTIONS.AUTH.CLEAR_USER_DATA:
          localStorage.removeItem("u_token");
          localStorage.removeItem("u_name");
          return {
            ...state,
            userToken: null,
            userName: null,
          }
        case ACTIONS.AUTH.REDIRECT:
          return {
            ...state,
            redirect: action.payload
          }
        default:
            return state;
    }
}
