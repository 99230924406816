import React from 'react';
import Header from './header';
import Footer from './footer';


const Page404 = (props) => {
  return(<>
      <Header />
      <div className="container margin_60_35">
          <div className="text-center" style={{margin: "100px", paddingTop: "40px"}}>
            <div>
              <img src="/img/404-img-text.png" alt="" />
            </div>
            <h4 style={{ paddingTop: "20px", paddingRight: "60px"}}>Page not found</h4>
          </div>
      </div>
      <Footer />
  </>)
}

export default Page404;
