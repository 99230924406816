import React from 'react';
import {connect} from 'react-redux';

import Header from './static_pages/header';
import FolderList from './items/FolderList';
import Footer from './static_pages/footer';

import { getFolderListRequest } from '../redux/requests';


class MainPage extends React.Component {

  componentDidMount() {
    // console.log("==start loading folder list");
    this.props.getFolderList(this.props.token, false);
  }


  render() {
    return(
      <div id="mm-0" className="mm-page mm-slideout">
        <div id="page">
          <Header />
          <FolderList 
                folderList={this.props.folderList}
                loading={this.props.recordListLoading} 
                loadError={this.props.loadError} />
          <Footer />
	      </div>
        <div id="toTop" ></div>
      </div>
    )
  }
}

function mapStateToProps(state) {
    return {
      loggedIn: state.auth.userName && state.auth.userToken,
      token: state.auth.userToken,
      folderList: state.record.folderList,
      recordListLoading: state.record.recordListLoading,
      loadError: state.record.loadError,
    };
}

function mapDispatchToProps(dispatch) {
    return {
      getFolderList: (token) => dispatch(getFolderListRequest(token)),
    };
}


const MainPageContainer = connect(mapStateToProps, mapDispatchToProps)(MainPage);
export default MainPageContainer;