export function zPad(i, n) {
    return ('0000' + i).slice(-n);
}

export function toTime(tc, frequency) {
    let s = Math.round(tc / frequency);
    let m = Math.floor(s / 60);
    s = zPad(s - m * 60, 2);
    return `${m}:${s}`;
}

export function toProc(v, t) {
    return t > 0 ? Math.round(v * 1000 / t) / 10 : 0;
}
