export const zeroPad = (num, places) => String(num).padStart(places, '0');


export function detectRecordDate(rec_date) {
    if (!rec_date) {
        return null;
    }
    
    const date = new Date((rec_date+'+').slice(0, rec_date.lastIndexOf('+')));
    // const offset = (date.getTime() + date.getTimezoneOffset() * 60 * 1000) / 1000;
    // date.setTime(date.getTime() + offset/3600);
    return zeroPad(date.getMonth() + 1, 2) + '/' + zeroPad(date.getDate()) + '/' + date.getFullYear() + ' ' + 
        zeroPad(date.getHours(), 2) + ':' + zeroPad(date.getMinutes(), 2) + ":" +zeroPad(date.getSeconds(), 2);
}