import React from 'react';
import {NavLink} from 'react-router-dom';


export default class Footer extends React.Component {
  render() {
    return(
      <footer style={{backgroundColor: "#f5f8fa"}}>
        <div className="container margin_10_35">
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <p>
                <NavLink exact to="/" title="ECG DEMO">
                  <img src="/img/logo.png" data-retina="true" alt="" width="163" height="36" className="img-fluid" />
                </NavLink>
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <h5>Pages</h5>
            <ul className="links">
              <li><NavLink exact to="/">Home</NavLink></li>
            </ul>
          </div>

          <hr />
          <div className="row">
            <div className="col-md-8">
              <ul id="additional_links">
                <li><a href="#terms">Terms and conditions</a></li>
              </ul>
            </div>
            <div className="col-md-4">
              <div id="copy">© 2022-2024 ECG DEMO</div>
            </div>
          </div>
        </div>
      </footer>

    )
  }
}

