import { useState, useEffect } from 'react';

export const useContainerDimensions = myRef => {
    const getDimensions = (ref) => ({
        width: ref.current ? ref.current.offsetWidth : 0,
        height: ref.current ? ref.current.offsetHeight : 0
    })

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

    useEffect(() => {
        const handleResize = () => {
            setDimensions(getDimensions(myRef))
        }

        if (myRef.current) {
            setDimensions(getDimensions(myRef))
        }

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [myRef])

    return dimensions;
};
