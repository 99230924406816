import React from 'react';

import {
    PAPER_CELL_WIDTH, PAPER_CELL_HEIGHT, SIGNAL_COLOR, MV1_COLOR
} from "../../const/signal_consts";

import { getTickByX, getBeatColor, getUserBeatColor, getRhythmColor, getRhythmColorByCode } from "./calc-funcs";

const previewPaperCellWidth = Math.round(2*PAPER_CELL_WIDTH / 5); 
const previewPaperCellHeight = Math.round(2*PAPER_CELL_HEIGHT / 5); 

export function detectWindowLengthInSec(windowWidth, paperSpeed) {
    const n = 5*Math.floor(windowWidth / (5*previewPaperCellWidth))
    return n * 5 / paperSpeed;
}


export function getRhythmItem(lbl, title, timeStr, procVal, mCount, rsiCode) {
    const bkColor = getRhythmColorByCode(rsiCode);
    const lbl_ex = mCount > 0 ? lbl + '*' : lbl;
    return procVal > 0 ? (
        <div className='ecg-param-group'>
            <label className="ecg-param-title-s" style={{ backgroundColor: bkColor, paddingLeft: 2 }} title={title}>{lbl_ex}</label>
            <label className="ecg-param-value-s">{timeStr}</label>
            <span className="ecg-param-unit-s" >({procVal}%)</span>
        </div>
    ) : (
       <div className='ecg-param-group'>
        <label className="ecg-param-title-s" title={title}>{lbl}</label>
        <label className="ecg-param-value-s">-</label>
       </div>
    )
}

export function getLinearData(start, finish, step) {
  let v = start;
  const data = [];
  while (v <= finish) {
     data.push(v);
     v += step;
  }
  return data;
}

export function getArray(data) {   
   return data.map(d => d === 'nan' ? NaN : d);
}

export function filterNan(data) {   
   return data.filter(d => !isNaN(d));
}

export function draw1mv(ecgAmp, w = PAPER_CELL_WIDTH, h = 7 * PAPER_CELL_HEIGHT + 1) {
    let items = [];
    const dw1 = w / 3;
    const dw2 = 2 * w / 3;
    if (ecgAmp !== undefined) {
        const color = MV1_COLOR;
        const iw = 2;
        const z = h;
        const ch = z - PAPER_CELL_HEIGHT * ecgAmp / 10;
        items.push((<line key={items.length} x1="0" y1={z} x2={dw1} y2={z} style={{ stroke: color, strokeWidth: iw }} />));
        items.push((<line key={items.length} x1={dw1} y1={z} x2={dw1} y2={ch} style={{ stroke: color, strokeWidth: iw }} />));
        items.push((<line key={items.length} x1={dw1} y1={ch} x2={dw1 + dw2} y2={ch} style={{ stroke: color, strokeWidth: iw }} />));
        items.push((<line key={items.length} x1={dw1 + dw2} y1={ch} x2={dw1 + dw2} y2={z} style={{ stroke: color, strokeWidth: iw }} />));
        items.push((<line key={items.length} x1={dw1 + dw2} y1={z} x2={dw1 * 2 + dw2} y2={z} style={{ stroke: color, strokeWidth: iw }} />));
    }
    return (<svg xmlns="http://www.w3.org/2000/svg" width={dw2 + dw1 * 2} height={h + 2}> {items}</svg>);
}


function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
}

function getRandomArray(n = 1000) {
    return Array.from({ length: n }, () => getRandomInt(-1500, 1500));
}


export function drawSignalAsLines(signal, offset, selected, w = 900, h = 8 * PAPER_CELL_HEIGHT, frequency = 256, paperSpeed = 25, ecgAmp = 10) {
    const color = SIGNAL_COLOR;
    const iw = 2;

    if (!signal) {
        signal = getRandomArray();
    }

    const mm_x = PAPER_CELL_WIDTH / 10;
    const i_max = getTickByX(w, paperSpeed, frequency, mm_x); //   Math.floor(w / mm_x / paperSpeed * frequency);
    const yz = h / 2;
    let x0 = null;
    let y0 = null;

    let items = [];

    const [s, d] = selected;
    if (d > 0) {
        const xs = Math.floor((s - offset) / frequency * paperSpeed * mm_x);
        const xd = Math.floor(d / frequency * paperSpeed * mm_x);

        const selectedRect = (<rect key={0} x={xs} y={0} width={xd} height={h} stroke="transparent" fill="blue" fillOpacity="0.05" />);
        items.push(selectedRect);
    }

    const ampC = PAPER_CELL_HEIGHT / 1000 * ecgAmp / 10;
    signal.slice(offset, offset + i_max).forEach((v, i) => {
        const x = Math.floor(i / frequency * paperSpeed * mm_x);
        const y = yz - Math.floor(v * ampC);
        if ((x0) && (y0)) {
            if (!((x === x0) && (y === y0))) {
                items.push((<line key={i + 1} x1={x0} y1={y0} x2={x} y2={y} style={{ stroke: color, strokeWidth: iw }} />));
            }
        }
        y0 = y;
        x0 = x;
    });

    return (<svg xmlns="http://www.w3.org/2000/svg" width={w} height={h}> {items}</svg>);
}

export function drawSignalAsPoly(signal, offset, selected, w = 900, h = 8 * PAPER_CELL_HEIGHT, frequency = 256, paperSpeed = 25, ecgAmp = 10, color=SIGNAL_COLOR) {
    const iw = 2;

    if (!signal) {
        signal = getRandomArray();
    }

    const mm_x = PAPER_CELL_WIDTH / 10;
    const i_max = getTickByX(w, paperSpeed, frequency, mm_x); //   Math.floor(w / mm_x / paperSpeed * frequency);
    const yz = h / 2;
    let x0 = null;
    let y0 = null;

    let items = [];

    const [s, d] = selected;
    if (d > 0) {
        const xs = Math.floor((s - offset) / frequency * paperSpeed * mm_x);
        const xd = Math.floor(d / frequency * paperSpeed * mm_x);

        const selectedRect = (<rect key={0} x={xs} y={0} width={xd} height={h} stroke="transparent" fill="blue" fillOpacity="0.05" />);
        items.push(selectedRect);
    }

    const ampC = PAPER_CELL_HEIGHT / 1000 * ecgAmp / 10;
    let i = offset;
    if (i < signal.length) {
        let v0 = signal[i];
        let v = v0;
        x0 = Math.floor((i - offset) / frequency * paperSpeed * mm_x);
        y0 = yz - Math.floor(v * ampC);
        let x = x0; let y = y0;
        let dd = `M${x0},${y0}`;
        i++;
        while (i < Math.min(offset + i_max, signal.length)) {
            v = signal[i];
            x = Math.floor((i - offset) / frequency * paperSpeed * mm_x);
            y = yz - Math.floor(v * ampC);
            if (!((x === x0) && (y === y0))) {
                dd += ` ${x},${y}`;
                x0 = x; y0 = y;
            }
            i++;
        }
        const path = (<path d={dd} key="signal" stroke={color} strokeWidth={iw} fill="none"/>);
        items.push(path);
    }
    return (<svg xmlns="http://www.w3.org/2000/svg" width={w} height={h}> {items}</svg>);
}


export function drawSignalAsPoly2(signal, frequency, color, wPaperCell, hPaperCell, xz, yz, signalGain=20, paperSpeed=25, revPolarity=false) {
    const iw = 2;

    let x0 = null;
    let y0 = null;

    const ampCAbs = hPaperCell / 5 * signalGain / 1000;
    const ampC = revPolarity ? -ampCAbs : ampCAbs;
    const scaleX = wPaperCell / 5 * paperSpeed / frequency;

    let i = 0;
    let v0 = signal[i];
    let v = v0;
    x0 = xz + Math.floor(i * scaleX);
    y0 = yz - Math.floor(v * ampC);
    let x = x0; let y = y0;
    let dd = `M${x0},${y0}`;
    i++;
    while (i < signal.length) {
        v = signal[i];
        x = xz + Math.floor(i * scaleX);
        y = yz - Math.floor(v * ampC);
        if (!((x === x0) && (y === y0))) {
            dd += ` ${x},${y}`;
            x0 = x; y0 = y;
        }
        i++;
    }
    return (<path d={dd} key="signal" stroke={color} strokeWidth={iw} fill="none" />);
}


export function getDrawWidth(wcSec, paperSpeed) {
    const wc = Math.round(wcSec * paperSpeed / 5);
    return wc * previewPaperCellWidth;
}

export function posToTime(pos, offsetSec, wcSec, paperSpeed) {
    const wc = Math.round(wcSec * paperSpeed / 5);
    const dw = Math.round(previewPaperCellWidth / 10);
    const ww = wc * previewPaperCellWidth;
    const xz = previewPaperCellWidth + dw;
    const t = offsetSec + wcSec * Math.max(0, (pos - xz)) / ww
    // console.log(wcSec, wc, t);
    return Math.round(t*1000) / 1000;

}

export function timeToPos(tSec, offsetSec, wcSec, paperSpeed) {
    const wc = Math.round(wcSec * paperSpeed / 5);
    const dw = Math.round(previewPaperCellWidth / 10);
    const ww = wc * previewPaperCellWidth;
    const xz = previewPaperCellWidth + dw;
    const p = xz + ww*(tSec - offsetSec) / wcSec;
    //console.log(p, xz, wcSec, tSec);
    return Math.round(p);
}


export function drawReportPaper(signal, signal2, beats, userBeats=null, rhythm = null, frequency = 256, offsetSec = 0, wcSec = 15, hc = 12, 
                                signalGain = 20, paperSpeed = 25, showRevPolarity = false, showRRs = true, 
                                showBeatMarkers = true, showRhythm = true, rr_hr_mode, selected=null) {
    const wc = Math.round(wcSec * paperSpeed / 5);
    const dw = Math.round(previewPaperCellWidth / 10);
    const dh = Math.round(previewPaperCellHeight / 10);
    const nz = Math.round(hc / 2);

    const xz = previewPaperCellWidth + dw;
    const ww = wc * previewPaperCellWidth;
    const w = xz + ww + dw;
    const hh = hc * previewPaperCellHeight + dh;
    const h = hh + previewPaperCellHeight;

    const yz = nz * previewPaperCellWidth + dh;
    const y1 = yz - Math.round(previewPaperCellHeight * signalGain / 5);
    const y1m = yz + Math.round(previewPaperCellHeight * signalGain / 5);

    const items = [];
    const colorLineSel = '#888888'; //; '#F0B0B0'
    const colorLineMayor = '#B0B0B0';
    const colorLineMenor = '#E8E8E8';
    const colorNums = '#E0B0B0';

    const q1rMin = Math.round(12 / 1000 * frequency);  // 3ticks
    const q1rMax = Math.round(160 / 1000 * frequency);
    const rjMin = Math.round(20 / 1000 * frequency);  // 5ticks
    const rjMax = Math.round(200 / 1000 * frequency);

    const gPaper = [];
    for (let nx = 0; nx < wc; nx++) {
        const x = xz + nx * previewPaperCellWidth;
        for (let tx = 1; tx < 5; tx++) {
            const xx = x + Math.round(previewPaperCellWidth * tx / 5);
            gPaper.push((<line key={gPaper.length} x1={xx} y1={dh} x2={xx} y2={hh} />));
        }
    }

    for (let ny = 0; ny < hc; ny++) {
        const y = dh + ny * previewPaperCellHeight;
        for (let ty = 1; ty < 5; ty++) {
            const yy = y + Math.round(previewPaperCellHeight * ty / 5);
            gPaper.push((<line key={gPaper.length} x1={xz} y1={yy} x2={xz + ww} y2={yy} />));
        }
    }
    items.push((<g key={items.length} style={{ stroke: colorLineMenor }}>{gPaper} </g>));

    const scaleX = previewPaperCellWidth / 5 * paperSpeed / frequency;
    if (showBeatMarkers && beats) {
        const gBeats = [];
        beats.forEach(b => {
            const q1r = b.q1r ? Math.min(q1rMax, Math.max(q1rMin, b.q1r)) : q1rMin; 
            const rj = b.rj ? Math.min(rjMax, Math.max(rjMin, b.rj)) : rjMin;
            const x1 = xz + Math.floor((b.r0 - q1r) * scaleX);;
            const x2 = xz + Math.floor((b.r0 + rj) * scaleX);;
            const c = getBeatColor(b);
            gBeats.push((<rect key={gBeats.length} x={x1} y={dh} width={x2 - x1} height={hh - dh} fill={c} />));
        });
        items.push((<g key={items.length}>{gBeats}</g>));
    }

    if (showRhythm && rhythm && beats) {
        const gParts = [];
        let x1 = xz;
        const yh = 2*previewPaperCellHeight / 5;
        const y = dh + (hc-1) * previewPaperCellHeight + 2*yh;
        beats.forEach(b => {
            const x2 = xz + Math.floor(b.r0 * scaleX);
            const [c, m] = getRhythmColor(b.r0 + b.o, rhythm);
            if (c)
             if (m) {
                 gParts.push((<rect key={gParts.length} x={x1} y={y - yh} width={x2 - x1} height={2.5 * yh} fill={c} fillOpacity="0.5" />));
             } else {
                 gParts.push((<rect key={gParts.length} x={x1} y={y} width={x2 - x1} height={yh} fill={c} fillOpacity="0.85" />));
             }
                
            x1 = x2;
        });
        items.push((<g key={items.length}>{gParts}</g>));

    }


    const gGrid = [];
    for (let ny = 0; ny <= hc; ny++) {
        const y = dh + ny * previewPaperCellHeight;
        const c = ([yz, y1, y1m].indexOf(y) >= 0) ? colorLineSel : colorLineMayor;  
        gGrid.push((<line key={gGrid.length} x1={xz - dw} y1={y} x2={xz + ww} y2={y} style={{ stroke: c }} />));
    }

    for (let nx = 0; nx <= wc; nx++) {
        const x = xz + nx * previewPaperCellWidth;
        const [y2, c] = nx % 5 === 0 ? [hh+2*dh, colorLineSel] : [hh, colorLineMayor];
        gGrid.push((<line key={gGrid.length} x1={x} y1={dh} x2={x} y2={y2} style={{ stroke: c }} />));
    }
    items.push((<g key={items.length}>{gGrid}</g>));

    const gText = [];
    const xScale = previewPaperCellWidth * paperSpeed / 5;
    let ns0 = Math.ceil(offsetSec);
    for (let ns = ns0; ns < offsetSec+wcSec; ns++) {
        const x = xz + Math.round((ns - offsetSec) * xScale);
        if ((paperSpeed > 20) || (ns % 2 === 0))
          gText.push((<text key={gText.length} x={x} y={h - dh} >{ns}s</text>));  
    }
    items.push((<g key={items.length} textAnchor="middle" fill={colorNums}>{gText}</g>));

    const gScale = []
    gScale.push((<text key={gScale.length} x={xz - 2 * dw} y={yz}>0</text>));  
    gScale.push((<text key={gScale.length} x={xz - 2 * dw} y={y1}>1</text>));  
    gScale.push((<text key={gScale.length} x={xz - 2 * dw} y={y1m}>-1</text>));  
    items.push((<g key={items.length} textAnchor="end" dominantBaseline="middle" fill={colorNums}>{gScale}</g>));

    if (showRRs && beats) {
        const gRRs = [];
        if (rr_hr_mode) {
            beats.slice(1, beats.length).forEach(b => {
                const hr = Math.round(60 * frequency / b.rr);
                const dx = Math.floor((b.rr) * scaleX);
                if (dx > 2 * previewPaperCellWidth) {
                    const x = xz + Math.floor((b.r0 - b.rr / 2) * scaleX);
                    if (x < w) gRRs.push(<text key={gRRs.length} x={x} y={previewPaperCellHeight} >{hr}</text>);

                }
            });
        } else {
            beats.slice(1, beats.length).forEach(b => {
                const rrMS = Math.round(1000 * b.rr / frequency);
                const dx = Math.floor((b.rr) * scaleX);
                if (dx > 2 * previewPaperCellWidth) {
                    const x = xz + Math.floor((b.r0 - b.rr / 2) * scaleX);
                    if (x < w) gRRs.push(<text key={gRRs.length} x={x} y={previewPaperCellHeight} >{rrMS}ms</text>);

                }
            });
        }
        items.push((<g key={items.length} textAnchor="middle" style={{fontSize:"9"}}>{gRRs}</g>));

        if (userBeats) {
            const gUserBeats = [];
            userBeats.forEach(b => {
                const x = xz + Math.floor((b.r0) * scaleX);
                const c = getUserBeatColor(b, beats);
                gUserBeats.push((<polygon key={gUserBeats.length} points={`${x-4}, ${dh+3}, ${x}, ${dh+9}, ${x+4}, ${dh+3}`} fill={c} />));
            });
            items.push((<g key={items.length}>{gUserBeats}</g>));
    
        }

    }

    const gSignals = [];
    while (gSignals.length > 0) gSignals.pop();
    if (signal) {
        const path = drawSignalAsPoly2(signal, frequency, '#000000', previewPaperCellWidth, previewPaperCellHeight,
            xz, yz, signalGain, paperSpeed, showRevPolarity);
        gSignals.push(path);
    }

    if (signal2) {
        const path = drawSignalAsPoly2(signal2, frequency, '#808080', previewPaperCellWidth, previewPaperCellHeight,
            xz, yz, signalGain, paperSpeed, showRevPolarity);
        gSignals.push(path);
    }
    // console.log(gSignals);
    items.push((<g key={items.length} >{gSignals}</g>));

    if (selected) {
        // marker
        const xx = selected.markerX;
        if (xx >= xz)
            items.push((<line key={items.length} x1={xx} x2={xx} y1={dh+1} y2={hh-1} style={{ stroke: "black"}} />));

        const sr = selected.range;
        if (sr) {
            const x1 = Math.min(sr.s, sr.f);
            const x2 = Math.max(sr.s, sr.f);
            const x11 = Math.max(xz, x1);
            const x12 = Math.min(xz+ww, x2);
            if (x12 > x11) {
                items.push((<rect key={items.length} x={x11} y={dh} width={x12 - x11} height={hh - dh - 1} fill="navy" fillOpacity="0.25" />))
            }   
        }

        const sr2 = selected.range2;
        if (sr2) {
            const x1 = Math.min(sr2.s, sr2.f);
            const x2 = Math.max(sr2.s, sr2.f);
            const x11 = Math.max(xz, x1);
            const x12 = Math.min(xz+ww, x2);
            if (x12 > x11) {
                items.push((<rect key={items.length} x={x11} y={dh} width={x12 - x11} height={hh - dh - 1} fill="black" fillOpacity="0.25" />))
            }   
        }


    }

    const css = `
svg {
    font: 10px Verdana, Helvetica, Arial, sans-serif;
}
`

    items.push((<style key={items.length}>{css}</style>));

    return (<svg xmlns="http://www.w3.org/2000/svg" width={w} height={h}> {items}</svg>);
}

export function drawPlot(x_data, y_data, w = 180, h = 120, x_ticks=5, y_ticks=6, color="navy") {
    const items = [];
    const yyData = filterNan(y_data); 

    // ranges
    const xMin = Math.trunc(Math.min(...x_data));
    const xMax = Math.ceil(Math.max(...x_data));
    const xStep = Math.ceil((xMax - xMin) / x_ticks);
    // console.log(xMin, xMax, xStep, x_data);

    const yMin = Math.trunc(Math.min(...yyData));
    const yMax = Math.ceil(Math.max(...yyData));
    const yStep = Math.ceil((yMax - yMin) / y_ticks);
    // console.log(yMin, yMax, yStep, y_data);

    // frame & margins
    const ml = 10; const mr = 4; const mb = 12; const mt = 3; 
    items.push((<line key={items.length} x1={ml} y1={mt} x2={w-mr} y2={mt} style={{ stroke: "black", strokeWidth: "1px" }} />));
    items.push((<line key={items.length} x1={ml} y1={mt} x2={ml} y2={h - mb + mt} style={{ stroke: "black", strokeWidth: "1px" }} />));
    items.push((<line key={items.length} x1={ml} y1={h - mb + mt} x2={w-mr} y2={h - mb + mt} style={{ stroke: "black", strokeWidth: "1px" }} />));
    items.push((<line key={items.length} x1={w-mr} y1={mt} x2={w-mr} y2={h - mb + mt} style={{ stroke: "black", strokeWidth: "1px" }} />));

    // x-ticks
    let x = xMin;
    const gxTicks = [];
    while (x <= xMax) {
        const xx = Math.round(ml + (w - ml-mr) * (x - xMin) / (xMax - xMin));
        if ((xx > ml+1) && (xx < w - mr)) {
           items.push((<line key={items.length} x1={xx} y1={mt + 1} x2={xx} y2={h - mb + mt - 1} style={{ stroke: "#888888", strokeWidth: "1px", strokeDasharray: 3 }} />));
        }
        items.push((<line key={items.length} x1={xx} y1={h - mb+2} x2={xx} y2={h - mb + 5} style={{ stroke: "#808080", strokeWidth: "1px"}} />));
        gxTicks.push((<text key={gxTicks.length} x={xx} y={h} >{x}</text>));

        x += xStep;
    }
    items.push((<g key={items.length} textAnchor="middle" style={{ fontSize: "6" }}>{gxTicks}</g>));

    // y-ticks
    let y = yMin;
    const gyTicks = [];
    while (y <= yMax) {
        const yy = Math.round(mt + h-mb - (h - mt-mb) * (y - yMin) / (yMax - yMin));
        if ((yy > mt+1) && (yy < h - mb)) {
           items.push((<line key={items.length} x1={ml+1} y1={yy} x2={w - mr - 1} y2={yy} style={{ stroke: "#888888", strokeWidth: "1px", strokeDasharray: 3 }} />));
        }
        items.push((<line key={items.length} x1={ml-3} y1={yy} x2={ml - 1} y2={yy} style={{ stroke: "#808080", strokeWidth: "1px"}} />));
        gyTicks.push((<text key={gyTicks.length} x={ml-5} y={yy+2} >{y}</text>));

        y += yStep;
    }
    items.push((<g key={items.length} textAnchor="end" style={{ fontSize: "6" }}>{gyTicks}</g>));


    x = x_data[0];
    y = y_data[0];
    let px = Math.round(ml + (w - ml-mr) * (x - xMin) / (xMax - xMin));
    let py = Math.round(mt + h-mb - (h - mt-mb) * (y - yMin) / (yMax - yMin));
    let dd = `M${px},${py}`;

    for (let i=1;i< x_data.length; i++) {
       x = x_data[i];
       y = y_data[i];
       if (!isNaN(y)) {
         px = Math.round(ml + (w - ml-mr) * (x - xMin) / (xMax - xMin));
         py = Math.round(mt + h-mb - (h - mt-mb) * (y - yMin) / (yMax - yMin));
         dd += ` ${px},${py}`;
       }
    }
    const path = (<path d={dd} key="data" stroke={color} strokeWidth={1} fill="none"/>);
    items.push(path);


const css = `
svg {
    font: 10px Verdana, Helvetica, Arial, sans-serif;
}
`
    items.push((<style key={items.length}>{css}</style>));
    return (<svg xmlns="http://www.w3.org/2000/svg" width={w} height={h}> {items}</svg>);
}    
