import React from 'react';

import "../../css/info-page.css";

const InfoPage = (props) => {
    let { message, kind } = props;
    if (!kind) kind = "info";

    return (
        <div className='info-page'>
            <div className={`alert alert-${kind}`} role="alert">
                {message}
            </div>
        </div>
    )
}


export default InfoPage;
