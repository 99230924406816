import React from 'react';
import { connect } from 'react-redux';

import { localeStr } from '../../const/signal_consts';

const dateFormatStr = (date) => {
  return date.toLocaleDateString(localeStr) + ' ' + date.toLocaleTimeString(localeStr);
}


const TimeInfoItem = (props) => {
  const { offset, recordData, recordStartDateTime } = props ;
  const { frequency }  = recordData;  
  const offsetSec = Math.round(offset / frequency);

  let curDate = !recordStartDateTime ? new Date('10/10/2022 10:00:00') : new Date(recordStartDateTime);
  curDate.setSeconds(curDate.getSeconds() + offsetSec);
  const infoTimestr = dateFormatStr(curDate);

  return(
      <div className="time-info-panel">
         <label> {infoTimestr} </label>
      </div>
    )
}


function mapStateToProps(state) {
  return {
    recordData: state.record.recordData,
    recordStartDateTime: state.record.recordStartDateTime,
    offset: state.record.recordOffset,
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}


const TimeInfoContainer = connect(mapStateToProps, mapDispatchToProps)(TimeInfoItem);
export default TimeInfoContainer;
