import React from 'react';
import { useState } from 'react';

import { drawReportPaper, getDrawWidth, posToTime, timeToPos } from "../tools/signal-funcs";
import { getRangeByBeatNo, findNearestBeat } from "../tools/beat-funcs";


const SignalRowItem = (props) => {

    const [isHovered, setIsHovered] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [currentPos, setCurrentPos] = useState(-1);

    const { signal, signal2, beats, userBeats, rhythm, offset, length, frequency, gain, paperSpeed, beatMode, selectedBeat, rr_hr_mode,
        showRevPolarity, showRRs, showBeatMarkers, showRhythm, selectedRange, PositionUpdateHandler, RangeUpdateHandler, BeatChangeHandler } = props;
    // console.log(props);

    const getMousePos = (event) => {
        const dx = event.currentTarget ? event.currentTarget.offsetLeft : 0;
        return isHovered ? event.clientX - dx : -1;
    }

    const setLocalSelectedRangePos = (x, bUpdateRange) => {
        if (currentPos !== x) {
            setCurrentPos(x);
            PositionUpdateHandler(x > 0 ? posToTime(x, offset, length, paperSpeed) : null);
        }

        if (!bUpdateRange) return;

        const t = posToTime(x, offset, length, paperSpeed);

        let t1 = t;
        let t2 = t;
        if (selectedRange.start >= 0) {
            t1 = t < selectedRange.start ? t : selectedRange.start;
            t2 = t > selectedRange.finish ? t : t > selectedRange.start ? t : selectedRange.finish;
        }
        // console.log(selectedRange, x, t, t1, t2);

        if (RangeUpdateHandler) RangeUpdateHandler(t1, t2);
        
    }

    const clearSelectedRange = () => {
        if (RangeUpdateHandler) RangeUpdateHandler(-1, -1);
    }

    const selectBeatByPos = (posX) => {
        const tc = Math.round(frequency*posToTime(posX, offset, length, paperSpeed));
        const b = findNearestBeat(tc, beats);
        if (b) {
            BeatChangeHandler(b.i);
        }
    }


    const handleMouseEnter = (event) => {
        setIsHovered(true);
        if (event.buttons === 1) {
            setIsMouseDown(true);
        }
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        setCurrentPos(-1);
        setIsMouseDown(false);
        PositionUpdateHandler(null);
    };

    const handleWindowClick = (event) => {
        if (!isHovered) {
            setIsHovered(true);
        }
    };


    const handleWindowMouseDown = (event) => {
        setIsMouseDown(true);

        if (beatMode) {
            const x = getMousePos(event);
            selectBeatByPos(x);
        } else {

            if (event.ctrlKey && selectedRange.start >= 0) {
                const x = getMousePos(event);
                setLocalSelectedRangePos(x, true);
            } else {
                clearSelectedRange();
            }
        }

        event.cancelBubble = true;
        if (event.stopPropagation) event.stopPropagation();
        return false;
    };

    const handleWindowMouseUp = (event) => {
        setIsMouseDown(false);
    };


    const handleWindowMouseMove = (event) => {
        if (event.buttons === 1 && !isMouseDown) {
            // console.log("set mouse down");
            setIsMouseDown(true);
        }

        if (event.buttons === 0 && isMouseDown) {
            // console.log("clear mouse down");
            setIsMouseDown(false);
        }
        if (!isHovered) return;

        const x = getMousePos(event);
        if (beatMode) {
            //const t = posToTime(x, offset, length, paperSpeed);
            //const b = searchBeatByTime(beats);
        } else {
            setLocalSelectedRangePos(x, isMouseDown);
        }
    };

    const wc = length;
    const hc = 12;

    const selStartPos = timeToPos(selectedRange.start, offset, length, paperSpeed); 
    const selFinishPos = timeToPos(selectedRange.finish, offset, length, paperSpeed);
    //console.log(selectedRange.start, selStartPos, selFinishPos) ;

    const beatRange = beatMode ? getRangeByBeatNo(selectedBeat, beats, frequency) : null;
    const selBeatStartPos = beatRange ? timeToPos(beatRange.start, offset, length, paperSpeed) : -1; 
    const selBeatFinishPos = beatRange ? timeToPos(beatRange.finish, offset, length, paperSpeed) : -1; 

    const img = drawReportPaper(signal, signal2, beats, userBeats, rhythm, frequency, offset, wc, hc, gain, paperSpeed, 
        showRevPolarity, showRRs, showBeatMarkers, showRhythm, rr_hr_mode,
        { markerX: currentPos, range: {s: selStartPos, f: selFinishPos }, range2: {s: selBeatStartPos, f: selBeatFinishPos}});

    return (
        <div className='ecg-signal-row' style={{ padding: "10px 0px" }}>
            <div className="ecg-signal-wrap-row prevent-select" draggable="false"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseDown={handleWindowMouseDown}
                onMouseUp={handleWindowMouseUp}
                onMouseMove={isHovered ?  handleWindowMouseMove: null}

                onClick={handleWindowClick}
                >
                {img}
            </div>
        </div>    
    );
}

export function getRowWidth(length, paperSpeed) {
    return getDrawWidth(length, paperSpeed) + 20;
}

export default SignalRowItem;
