import React from 'react';
import { connect } from 'react-redux';
import { HashRouter as Router, Route, redirect, Routes } from 'react-router-dom';  // BrowserRouter as Router, 

import { setRedirect } from './redux/actions';

import './css/all_icons_min.css';
import './css/hero.css';
import './css/menu.css';

import Page404 from './components/static_pages/page404.js';
import MainPage from './components/main_page';

import FolderPage from './components/folder_page';
import FolderStatPage from './components/folder_stat_page';
import FolderTrainPage from './components/folder_train_page';

import RecordPage from './components/record_page';
import ReportPage from './components/report_page';


class App extends React.Component {
  render() {
    if (this.props.redirect) {
      this.props.setRedirect(null);
      return redirect(this.props.redirect)
    }

    return (
      <Router>
          <Routes>
            <Route exact path="/" element={<MainPage />} />
            <Route path="/folder/:folder_id/record/:id" element={<RecordPage />} />
            <Route path="/folder/:folder_id/report/:id" element={<ReportPage />} />
            <Route path="/folder/:id/stat" element={<FolderStatPage />} />
            <Route path="/folder/:id/train" element={<FolderTrainPage />} />
            <Route path="/folder/:id" element={<FolderPage />} />
            <Route path="/record/:id" element={<RecordPage />} />
            <Route path="/report/:id" element={<ReportPage />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Router>
    )
  }
}

function mapStateToProps(state) {
  return {
    redirect: state.auth.redirect
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setRedirect: (uri) => dispatch(setRedirect(uri))
  };
}


const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
// export { AppContainer };
export default AppContainer;

