import React from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

import Header from './static_pages/header';
import FolderTrain from './items/FolderTrain';
import Footer from './static_pages/footer';

import { getFolderStatRequest } from '../redux/requests';


class FolderTrainPage extends React.Component {

  constructor(props) {
    super(props);
    // this.folder_id = props.match.params.id;
    this.folder_id = props.folder_id;
  }

  componentDidMount() {
    if (this.folder_id) {
      this.props.getFolderStat(this.folder_id);
      console.log("==set loading folder record list: " + this.folder_id);
    }
  }

  render() {
    return (
      <div id="mm-0" className="mm-page mm-slideout">
        <div id="page">
          <Header />
          <FolderTrain folderStatTable={this.props.folderStatTable} folderStatTotal={this.props.folderStatTotal} 
            folder={this.props.folderName} folder_id={this.folder_id}
            folderStatLoading={this.props.folderStatLoading} 
            loadError={this.props.loadError} 
            onStartProcessRecord={this.onStartProcessRecord}/>
          <Footer />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.auth.userName && state.auth.userToken,
    folderName: state.record.folderName,
    folderStatTable: state.record.folderStatTable,
    folderStatTotal: state.record.folderStatTotal,
    folderStatLoading: state.record.folderStatLoading,
    loadError: state.record.loadError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFolderStat: (token) => dispatch(getFolderStatRequest(token)),
  };
}


const FolderTrainPageContainer = connect(mapStateToProps, mapDispatchToProps)(FolderTrainPage);

const FolderTrainPageMatch = (props) => {
  let {id} = useParams()
  return (<FolderTrainPageContainer folder_id={id} />);
}

export default FolderTrainPageMatch;
