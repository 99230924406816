import React from "react";


const ButtonsLfRt = (props) => {
  const { value, onDecIndex, onIncIndex } = props;
  return (
   <div className="btn-group btn-group-sm2" role="group" aria-label="Button group with nested dropdown">
      <label type="button" className="btn btn-outline-primary btn-sm2 x-btn-left" onClick={onDecIndex}><i className="fa fa-chevron-left"></i></label>
      <label className="btn btn-outline-primary btn-sm2 x-btn-info"> {value} </label>
      <label type="button" className="btn btn-outline-primary btn-sm2 x-btn-right" onClick={onIncIndex}><i className="fa fa-chevron-right"></i></label>
   </div>  
  ); 
}

export default ButtonsLfRt;