import React from 'react';
import { Link } from "react-router-dom";

import InfoPage from './InfoPage';

import '../../css/table.css';



const FolderTable = (props) => {
    const { table } = props;

    if (!table) {
        return (
            <InfoPage message="Folder list has no table" kind="danger" />
        )
    }

    const folder_rows = table.map((r, i) => {
        return r.is_train ? 
        (<tr key={i} >
            <td className="text-center"> {i + 1} </td>
            <td className="text-left aa r-train"> <Link className="btn" to={`/folder/${r.token ? r.token : r.folder}/train`}>{r.name}</Link> </td>
        </tr>) : r.has_stat ? 
        (<tr key={i} >
            <td className="text-center"> {i + 1} </td>
            <td className="text-left aa r-stat"> <Link className="btn" to={`/folder/${r.token ? r.token : r.folder}/stat`}>{r.name}</Link> </td>
        </tr>) :
        (<tr key={i} >
            <td className="text-center"> {i + 1} </td>
            <td className="text-left aa"> <Link className="btn" to={`/folder/${r.token ? r.token : r.folder}`}>{r.name}</Link> </td>
        </tr>
         )

    }
);

    return (
        <div className='r-table'>
            <table className="table table-hover">
                <thead className="thead-dark">
                    <tr>
                        <th className="text-center" width="10%"> NN </th>
                        <th className="text-left"> Folder </th>
                    </tr>
                </thead>
                <tbody>
                    {folder_rows}
                </tbody>
                <tfoot className="thead-dark">
                    <tr>
                        <th className="text-center" width="10%"> NN </th>
                        <th className="text-left"> Total: {folder_rows.length} </th>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}


export default FolderTable;